import axios from "axios";
import config from "../config";
import { Auth } from "aws-amplify";
export const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const axiosInstance = axios.create({
  baseURL: config?.PROD_URL,
  timeout: 30000,
});

export const feedAxiosInstance = axios.create({
  baseURL: config?.FEED_URL,
  timeout: 30000,
});

feedAxiosInstance.interceptors.request.use(async (config) => {
  try {
    const currentSession = await Auth.currentSession();
    currentSession.getRefreshToken().getToken();
    const accessToken = currentSession.getAccessToken().getJwtToken();

    config.headers["x-access-token"] = accessToken;
  } catch (err) {}
  return config;
});

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const currentSession = await Auth.currentSession();
    currentSession.getRefreshToken().getToken();
    const accessToken = currentSession.getAccessToken().getJwtToken();

    config.headers["x-access-token"] = accessToken;
  } catch (err) {}
  return config;
});

export const updateAxiosInstance = (
  token: string,
) => {
  const user_data_for_localstorage = {
    token: token,
  };
  window.localStorage.setItem(
    "piq-auth-headers",
    JSON.stringify(user_data_for_localstorage)
  );

  axiosInstance.defaults.headers["x-access-token"] = token;
  
  feedAxiosInstance.defaults.headers["x-access-token"] = token;
 
};


export const get_auth_token = async() => {
  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getAccessToken().getJwtToken();
  return accessToken
}


export const remove_auth_headers = () => {
  window.localStorage.removeItem("piq-auth-headers");
  window.localStorage.removeItem("piq-auth-token"); //old token
  window.localStorage.removeItem("piq-suite-config"); //old data
};
