import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Auth } from "aws-amplify";

import { loginSchema, TLoginSchema } from "src/schema/auth/auth.schema";
import { axiosInstance, updateAxiosInstance } from "src/store/utility";
import { updateLoading } from "src/store/Loading";
import { useStepper } from "src/context/StepperProvider";
import { getUrlParam } from "src/utils/utilFunction";
import { useLayoutContext } from "src/context/LayoutProvider";
import { popMessage } from "src/development/Component/message/message";
import { EMAIL_REGEX } from "src/constants/auth";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";

const stepperSteps = {
  withCheckout: ["Sign In", "Checkout", "Launch Suite"],
  withoutCheckout: ["Verify Email", "Sign In", "Launch Suite"],
};

const updateUrlParams = (params: Record<string, string>) => {
  const searchParams = new URLSearchParams(window.location.search);
  Object.entries(params).forEach(([key, value]) => {
    if (key !== "email") {
      searchParams.set(key, value);
    }
  });
  return searchParams.toString();
};

export const useLogin = ({ promoCode }: { promoCode: string }) => {
  const [error, setError] = useState<string>("");
  const withCheckout = getUrlParam("with") === "checkout";

  const form = useForm<Partial<TLoginSchema>>({
    resolver: zodResolver(loginSchema.partial()),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setSteps } = useStepper();
  const { setActiveDrawer } = useLayoutContext();

  const { sendEvent } = useTrackerContext();

  // Watch for changes in the email field
  const email = form.watch("email");
  const currentStep = getUrlParam("step") || "1";
  const internalStep = parseInt(getUrlParam("internalStep") || "1");

  const setInternalStep = (step: number) => {
    const searchParams = updateUrlParams({ internalStep: step.toString() });
    navigate(`${location.pathname}?${searchParams}`);
  };

  // Initialize steps for the stepper
  useEffect(() => {
    setSteps(
      withCheckout ? stepperSteps.withCheckout : stepperSteps.withoutCheckout
    );
  }, [setSteps]);

  // Clear the error message when the user starts editing the email again
  useEffect(() => {
    if (error) {
      setError("");
    }
  }, [email]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("loginEmail");
    if (storedEmail && EMAIL_REGEX.test(storedEmail)) {
      form.setValue("email", storedEmail);
    }
  }, []);

  // Add validation for direct URL access
  useEffect(() => {
    const step = getUrlParam("step");
    const internalStepParam = parseInt(getUrlParam("internalStep") || "1");

    if ((step === "2" || internalStepParam > 1) && !email) {
      const searchParams = new URLSearchParams();
      searchParams.set("step", "1");
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location.search]);

  // Handles email verification step
  const handleEmailSubmit = async (payload: Partial<TLoginSchema>) => {
    try {
      const email = payload?.email?.toLowerCase();
      setError(""); // Clear any existing error
      sendEvent({
        eventName: trackEvents.CONTINUE_BTN,
        payload: {
          page: "signin",
          type: "email",
        },
      });

      const { status } = await axiosInstance.post(
        "/auth/check-email-verification",
        { email: email }
      );

      if (status === 200) {
        localStorage.setItem("loginEmail", email || "");
        if (withCheckout) {
          const searchParams = updateUrlParams({ internalStep: "4" });
          navigate(`${location.pathname}?${searchParams}`);
        } else {
          const searchParams = updateUrlParams({ step: "2" });
          navigate(`${location.pathname}?${searchParams}`);
        }
      }
    } catch (error) {
      console.log("error", error);
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 404) {
          setError(
            "We don't recognize this email address. Try another or Sign Up to register."
          );
        } else if (status === 403) {
          const searchParams = updateUrlParams({
            internalStep: "2",
            email: encodeURIComponent(email || ""),
          });
          localStorage.setItem("loginEmail", email || "");
          navigate(`${location.pathname}?${searchParams}`);
        } else {
          setError("An unexpected error occurred");
        }
      } else {
        console.error("An unexpected error occurred:", error);
        setError("An unexpected error occurred");
      }
    }
  };

  // Handles user login
  const handleLogin = async (payload: Partial<TLoginSchema>) => {
    const { data, success } = loginSchema.safeParse(payload);
    if (!success) return popMessage.errorMessage("Invalid Credentials");

    sendEvent({
      eventName: trackEvents.CONTINUE_BTN,
      payload: {
        page: "signin",
        type: "login",
      },
    });
    sendEvent({
      eventName: trackEvents.SIGN_IN_SUBMIT,
    });
    try {
      const user = await Auth.signIn(data.email?.toLowerCase(), data.password);
      const authToken = user.signInUserSession.accessToken.jwtToken;

      updateAxiosInstance(authToken);
      localStorage.setItem(
        "tokenExpirationDate",
        moment().add(1, "days").toISOString()
      );

      setActiveDrawer("");
      dispatch(updateLoading({ key: "dashboardloading", value: true }) as any);
      if (promoCode) {
        sendEvent({
          eventName: trackEvents.BULK_LICENSE_UPGRADE,
          payload: {
            code: promoCode,
          },
        });
        try {
          const response = await axiosInstance.post(
            `/enterprise-license/use/${promoCode}`
          );

          if (!response.data.success) {
            popMessage.errorMessage(response.data.message);
            sendEvent({
              eventName: trackEvents.BULK_LICENSE_UPGRADE_FAILURE,
              payload: {
                code: promoCode,
              },
            });
          } else {
            sendEvent({
              eventName: trackEvents.BULK_LICENSE_UPGRADE_SUCCESS,
              payload: {
                code: promoCode,
              },
            });
          }
        } catch (error: any) {
          if (error.response) {
            popMessage.errorMessage(error.response.data.message);
          } else {
            popMessage.errorMessage("Error applying promo code");
          }
        }
      }
      localStorage.removeItem("companyLicense");
      if (withCheckout) {
        const searchParams = updateUrlParams({ step: "3" });
        navigate(`${location.pathname}?${searchParams}`);
      } else {
        localStorage.setItem("loggedIn", "true");
        navigate("/");
        // popMessage.successMessage("Welcome back");
      }
      localStorage.removeItem("loginEmail");
    } catch (err: any) {
      if (err.code === "NotAuthorizedException") {
        form.setError("password", {
          message: "Incorrect password. Please try again.",
        });
      } else {
        popMessage.errorMessage(
          err.message || "Something went wrong. Please try again."
        );
      }

      sendEvent({
        eventName: trackEvents.LOGIN_FAIL,
      });
    } finally {
      dispatch(updateLoading({ key: "dashboardloading", value: false }) as any);
    }
  };

  return {
    form,
    error,
    internalStep,
    setInternalStep,
    currentStep,
    handleEmailSubmit,
    handleLogin,
  };
};
