import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import menyIcon from "src/style/images/Menu Icon.svg";
import { RefreshButtoneComponent } from '../RefreshButton/inde';

interface PodcastHeaderProps {
    title: string;
    handleEditPanel: () => any;
    reloadIframe: () => any;
    reloading: boolean;
}


const PodcastHeader = ({
    title,
    handleEditPanel,
    reloadIframe,
    reloading
}: PodcastHeaderProps) => {

    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    const handleReload = () => {
        reloadIframe && reloadIframe()
    }

    return (
        <div className="v2-column-header">
            <div className="pointer title-container"
            >
                <span className="v2-column-header-text   grid-drag-handle">
                    {title}
                </span>
                <RefreshButtoneComponent
                    fetchFunction={() => handleReload()}
                    isFetching={reloading}
                />
            </div>
            <div className="pointer filler grid-drag-handle"></div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    gap: "6px",
                }}
            >
                <div className="pointer filler grid-drag-handle"></div>
                <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                        e.preventDefault();
                        handleEditPanel()
                    }}
                >
                    <img
                        src={menyIcon}
                        alt="||"
                        className={`v2-column-header-option-icon ${darkMode ? "inverted" : ""
                            }`}
                    />
                </span>
            </div>
        </div>
    )
}

export default PodcastHeader