import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardloading: false,
  logoutloading: false,
};

interface FetchDataAction {
  payload: any; // Define the payload type for FETCH_DATA action
}

type ActionTypes = FetchDataAction; // Add other action types as needed

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    updateLoading: (state: any, action: ActionTypes) => {
      return {
        ...state,
        [action?.payload?.key]: action?.payload?.value,
      };
    },
  },
});

export const { updateLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
