import React, { Dispatch, SetStateAction, useEffect } from "react";
import './PasswordResetVerification.scss';
import OtpInput from "../../OtpInput/OtpInput";
import Button from "../../Button/Button";
import { usePasswordResetVerification } from "src/Hooks/auth/usePasswordVerification";
import BackButton from "../BackButton";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";
type Props = {
    setStep: (step: number) => void;
};

const PasswordResetVerification = (props: Props) => {
    const {
        otp,
        setOtp,
        error,
        setError,
        loading,
        handleSubmit,
        handleResendCode,
        email
    } = usePasswordResetVerification(props.setStep);

    const { sendEvent } = useTrackerContext();

    return (
        <div className="password-reset-verification-container">
            <h1>Password Reset</h1>
            <p>
                We have sent a password reset code to
                <strong> {email || "your email"}</strong>. Kindly check your email and enter the code below to change your password.
            </p>

            <form onSubmit={handleSubmit}>
                <h3>Enter Verification Code</h3>
                <div className="otp-input">
                    <OtpInput
                        onComplete={(otpval: string) => {
                            setOtp(otpval);
                            sendEvent({
                                eventName: trackEvents.PWD_RESET_VERIFICATION_CODE,
                                payload: {
                                    code: otpval
                                }
                            })
                        }}
                        size="52px"
                        error={!!error}
                        changeHandler={(val: string) => {
                            setError('');
                            setOtp(val);
                        }}
                    />
                </div>
                {error && (
                    <p className="error-text">{error}</p>
                )}

                <div style={{ display: "flex", gap: 8 }}>
                    <BackButton disabled={loading} />

                    <Button
                        text="Verify Code"
                        width="119px"
                        height="38px"
                        weight="semibold"
                        loading={loading}
                        disabled={otp?.length !== 6 || !!error}
                    />
                </div>
                {(otp.length !== 6 || !!error) &&

                    <div className="password-reset-verification-footer">
                        <span>
                            Didn't get the code?{" "}
                            <strong
                                onClick={(e) => {
                                    handleResendCode();
                                    sendEvent({
                                        eventName: trackEvents.PWD_RESET_VERIFICATION_RESEND_BTN
                                    })
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleResendCode();
                                        sendEvent({
                                            eventName: trackEvents.PWD_RESET_VERIFICATION_RESEND_BTN
                                        })
                                    }
                                }}
                                tabIndex={0}
                            >
                                Resend Code
                            </strong>
                        </span>
                    </div>
                }
            </form>
        </div>
    );
};

export default PasswordResetVerification;
