import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../utility";
import { check_if_token_is_expired } from "../userStore";

const initialState = {
  loading: false,
  data: {
    canceled: false,
    active: false,
  },
};

export const fetchSubscriptionData: any = createAsyncThunk(
  "subscription/fetchSubscriptionData",
  async (id, thunkApi) => {
    try {
      const res = await axiosInstance.get(`subscriptions/getSubcription/123`);
      return thunkApi.fulfillWithValue(res.data.data);
    } catch (err: any) {
      thunkApi.dispatch(check_if_token_is_expired(err.response));
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updateSubscription: (state: any) => {
      return {
        ...state,
        data: {
          ...state.data,
          canceled: true,
        },
      };
    },
    resetSubscription: (state: any) => {
      return {
        loading: false,
        data: {
          canceled: false,
          active: false,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriptionData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchSubscriptionData.rejected, (state, action) => {
        state.loading = false;

        state.data = {
          canceled: false,
          active: false,
        };
      });
  },
});

export const { updateSubscription, resetSubscription } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
