import { useState } from "react";
import { colState } from "../interface/PiqState";
import { useSelector } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";
import { RemoveSuiteLayoutCustomItem, RemoveSuiteLayoutItem, updateLocalStorageSuiteLayout } from "src/utils/common";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useLayoutContext } from "src/context/LayoutProvider";

const useColumnHeader = () => {
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [generateKey, setGenerateKey] = useState(0);
  const [alreadySelectedDialog, setAlreadySelectedDialog] = useState(false);
  const { isSignedIn: isUserLoggedIn, user } = useAuthContext();
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  const { hasSubscriptionForAdsRemoval } = useSelector((state: any) => ({
    hasSubscriptionForAdsRemoval: state.subscription?.data?.active || false,
  }));
  const Columns = useSelector((state: any) => {
    return state?.piqcolumns?.columns

  })

  const { setPiqSuiteLayout, piqSuiteLayout } = useLayoutContext()
  const subscriptionData = useSelector((state: any) => state.subscription);
  const layouts: any[] = piqSuiteLayout;
  const activeColums: any[] = piqSuiteLayout?.map(
    (item: any) => item?.i
  );
  const dispatch = useDispatch()
  const handleUpdateSaveColumn = (col: colState) => {
    const { newCol, oldCol } = col;

    if (!activeColums?.includes(newCol)) {
      const newType = Columns?.find(
        (item: any) => item?.slug === newCol
      )?.componentType;
      const oldType = Columns?.find(
        (item: any) => item?.slug === oldCol
      )?.componentType;
      const twitterFeed = piqSuiteLayout?.filter((item: any) => {
        return item?.type === "TWITTER_FEED_COMPONENT";
      });
      const newsFeed = piqSuiteLayout?.filter((item: any) => {
        return item?.type === "NEWS_FEED_COMPONENT";
      });
      if (newType === "NEWS_FEED_COMPONENT") {
        // if (!isUserLoggedIn) {
        //     message.warning("Twiiter feed is only available for signed in users");
        //     return;
        // }
        if (!isUserLoggedIn && newsFeed?.length >= 6) {
          message.warning("Maximum feed limit reached");
          return;
        }
        if (
          (hasSubscriptionForAdsRemoval || subscriptionData?.data?.active) &&
          newsFeed?.length >= 20
        ) {
          message.warning("Maximum feed limit reached");
          return;
        }
        if (
          (!hasSubscriptionForAdsRemoval || !subscriptionData?.data?.active) &&
          newType !== oldType &&
          newsFeed?.length === 10
        ) {
          message.warning("Feed limit reached. Upgrade to add more", 5);
          return;
        }
      }
      if (newType === "TWITTER_FEED_COMPONENT") {
        // if (!isUserLoggedIn) {
        //     message.warning("Twiiter feed is only available for signed in users");
        //     return;
        // }
        if (!isUserLoggedIn && twitterFeed?.length >= 1) {
          message.warning("Maximum feed limit reached");
          return;
        }
        if (
          (hasSubscriptionForAdsRemoval || subscriptionData?.data?.active) &&
          twitterFeed?.length >= 7
        ) {
          message.warning("Maximum feed limit reached");
          return;
        }
        if (
          (!hasSubscriptionForAdsRemoval || !subscriptionData?.data?.active) &&
          newType !== oldType &&
          twitterFeed?.length === 3
        ) {
          message.warning("X Feed limit reached. Upgrade to add more", 5);
          return;
        }
      }

      const index = activeColums.findIndex((item: string) => item === oldCol);
      if (index !== -1) {
        activeColums[index] = newCol;
      }
      const oldLayoutIndex = piqSuiteLayout?.findIndex(
        (item: any) => item?.i === oldCol
      );
      layouts[oldLayoutIndex]["i"] = newCol;

      setPiqSuiteLayout(
        layouts?.filter((item: any) => item.i !== oldCol)
      );

      const tempUser: any = user;
      updateLocalStorageSuiteLayout(
        layouts?.filter((item: any) => item.i !== oldCol),
        isUserLoggedIn,
        tempUser?.attributes?.email,
        darkMode
      );
    } else {
      setAlreadySelectedDialog(true);
      setShowSaveDialog(false);
    }
  };

  const removeColumn = (key: string) => {
    const removedCols = layouts.filter((item: any) => item.i !== key);
    const tempUser: any = user;
    setPiqSuiteLayout(removedCols);
    RemoveSuiteLayoutItem(
      removedCols,
      isUserLoggedIn,
      tempUser?.attributes?.email,
      darkMode
    );
  };
  const removeCustomColumn = (key: string) => {
    const removedCols = layouts.filter((item: any) => item.i !== key);
    const tempUser: any = user;
    setPiqSuiteLayout(removedCols);
    RemoveSuiteLayoutItem(
      removedCols,
      isUserLoggedIn,
      tempUser?.attributes?.email,
      darkMode
    );
    RemoveSuiteLayoutCustomItem(key, isUserLoggedIn)
  };

  return {
    handleUpdateSaveColumn,
    showSaveDialog,
    alreadySelectedDialog,
    setShowSaveDialog,
    setAlreadySelectedDialog,
    removeColumn,
    removeCustomColumn,
    setGenerateKey,
    generateKey
  };
};

export default useColumnHeader;
