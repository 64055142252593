import React from "react";

import useFeeds from "../../../Hooks/useFeeds";
import { SocketNames } from "src/interface/PiqState";

const MnemonicComponent: React.FC<{
  item: any;
  socket: SocketNames;
}> = ({ item, socket }) => {
  const { handleShowDetials } = useFeeds();

  return (
    <>
      {item?.mnemonic && (
        <div
          className="news-feeds-description"
          onClick={() => handleShowDetials(item, socket)}
        >
          {item?.mnemonic}
        </div>
      )}
    </>
  );
};

export default MnemonicComponent;
