import React, { useRef } from "react";
// import "video.js/dist/video-js.css";
import ReactHlsPlayer from "react-hls-player";
const IPTVPLayer: React.FC<{
  sources: string;
  autoplay: boolean;
  controls: boolean;
}> = ({ sources, autoplay, controls }) => {
  const videoNode = useRef(null);

  return (
    <div data-vjs-player>
      <ReactHlsPlayer
        className="video-js"
        playerRef={videoNode}
        src={sources}
        preload={"metadata"}
        autoPlay={autoplay}
        controls={controls}
      // hlsConfig={{
      //   maxBufferSize: 60*100
      // }}
      />
    </div>
  );
};
export default IPTVPLayer;
