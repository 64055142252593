import React from "react"
// import { getEconostreamDetail, getEmergningMarketWatchDetail, getFastrevealDetail, getForexLiveDetail, getHilltowerresourceadvisorsDetail, getReutersDetails } from "src/store/PIQ/action"
// import { getReutersDetails, setActiveDrawer } from "src/store/PIQ/action"
import { UpdateClickCount } from "src/store/RTM"
import { openNewWindow } from "src/utils/common"

export const RTM: React.FC<{ data: any, dispatch: any, notification: any }> = ({ data, dispatch, notification }) => {
    const handeleFooterclick = (data: any) => {
        UpdateClickCount(data?._id).then((res) => {
            if (data?.footer_link?.includes("piqsuite.com")) {
                let url = data?.footer_link?.split("piqsuite.com/")[1];
                // if (url.includes("reuters")) {
                //     url = url.replace("reuters/", "");
                //     (setActiveDrawer("article-details-drawer"));
                //     dispatch(getReutersDetails({ slug: url, twitter: true }, props) as any);
                // } else if (url.includes("econostream")) {
                //     url = url.replace("econostream/", "");
                //     dispatch(getEconostreamDetail(url, props) as any);
                // } else if (url.includes("forexLive")) {
                //     url = url.replace("forexLive/", "");
                //     dispatch(getForexLiveDetail(url, props) as any);
                // } else if (url.includes("fastreveal")) {
                //     url = url.replace("fastreveal/", "");
                //     dispatch(getFastrevealDetail(url, props) as any);
                // } else if (url.includes("hilltowerresourceadvisors")) {
                //     url = url.replace("hilltowerresourceadvisors/", "");
                //     dispatch(getHilltowerresourceadvisorsDetail(url, props) as any);
                // } else if (url.includes("emergingMarketWatch")) {
                //     url = url.replace("emergingMarketWatch/", "");
                //     dispatch(getEmergningMarketWatchDetail(url, props) as any);
                // }
                // else {
                //     openNewWindow(data?.footer_link);
                // }

            } else {

                openNewWindow(data?.footer_link);
            }
        });
        const time = setTimeout(() => notification?.close(data?._id), 1000);
        return () => clearTimeout(time);

    }
    return (
        <div
            className="rtm-notification"
        >
            <div className="rtm-header" hidden={!data?.header_text}>{data?.header_text}</div>
            <div className="rtm-body" hidden={!data?.body_text}>{data?.body_text}</div>
            <div className="rtm-footer" hidden={!data?.footer_text}
                onClick={() =>
                    handeleFooterclick(data)
                }
            >
                {/* <a target="_blank" href={data?.footer_link} rel='noreferrer'> */}
                {data?.footer_text}

                {/* </a> */}

            </div>

        </div>
    )
}
