import { Loading3QuartersOutlined } from "@ant-design/icons";
import React, { forwardRef, Ref, useEffect } from "react";
import { useSelector } from "react-redux";
import { CmeDetailProps } from "src/interface/cmeGroupApp";

export const CmeDetail = forwardRef<HTMLIFrameElement, CmeDetailProps>(({
    link,
    title,
    resetNavigation,
    setFetching,
    fetching,
    inFeedSharingModal
}, ref) => {

    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    useEffect(() => {
        setFetching(true)
    }, [link, title])

    return (
        <>
            <div
                className={`detail-container ${darkMode ? "dark" : ""}`}
                style={{
                    height: `${inFeedSharingModal ? "90%" : ""}`,
                    paddingTop: `${inFeedSharingModal ? "0px" : ""}`,
                }}
            >
                <div className="navigation">
                    <span className="link" onClick={resetNavigation}>
                        All Courses
                    </span>
                    <span>
                        /
                    </span>
                    <span>
                        {title}
                    </span>
                </div>
                <div className="iframe-container">
                    {
                        fetching && <div className="loader">
                            <Loading3QuartersOutlined spin size={32} />
                        </div>
                    }
                    <iframe src={link}
                        ref={ref}
                        id="cme-iframe"
                        allowFullScreen
                        height={"100%"}
                        width={"100%"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        onLoad={() => {
                            setFetching(false)
                        }}
                        onError={() => {
                            setFetching(false)
                        }}
                    ></iframe>
                </div>
            </div>
        </>
    );
});
