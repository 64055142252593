import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import './EmailVerification.scss';
import OtpInput from "../../OtpInput/OtpInput";
import Button from "../../Button/Button";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import BackButton from "../BackButton";


type Props = {
    nextStep: () => void;
    nextStepHandler: (otp: string) => void;
    resendOtpHandler: () => void;
    submitting: boolean;
    error?: boolean;
    setError?: Dispatch<SetStateAction<boolean>>;
    email: string;
    sendOtpByDefault?: boolean;
    onUnmount?: any;
};

const EmailVerification = ({ nextStep, nextStepHandler, resendOtpHandler, submitting, error = false, setError, email, sendOtpByDefault = true, onUnmount }: Props) => {

    const [otp, setOtp] = useState<string>("");
    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        nextStepHandler(otp)
    }

    const location = useLocation();

    useEffect(() => {
        if (email && sendOtpByDefault) {
            resendOtpHandler()
        }
    }, [email])

    useEffect(() => {


        return () => {
            if (onUnmount) {
                onUnmount()
            }
        }
    }, [])

    const { sendEvent } = useTrackerContext();
    const isRegisterPage = location.pathname === "/register";

    return <div className="email-verification-container">
        <h1>Email Verification</h1>
        <p>
            You need to verify your email! We have sent a verification code to <strong>
                {email}</strong>. Check your ‘Spam’ folder in case you can’t see it.
        </p>

        <form onSubmit={submitHandler}>
            <h3>Enter Verification Code</h3>
            <div className="otp-input">
                <OtpInput
                    onComplete={(otpval: string) => {
                        // setOtp(otpval)
                        sendEvent({
                            eventName: isRegisterPage ?
                                trackEvents.SIGNUP_EMAIL_VERIFICATION_CODE :
                                trackEvents.SIGNIN_EMAIL_VERIFICATION_CODE,
                            payload: {
                                code: otpval
                            }
                        })
                    }}
                    size="52px"
                    error={error}
                    setError={setError}
                    changeHandler={(otpval: string) => {
                        if (setError) setError(false)
                        setOtp(otpval)
                    }}
                />
            </div>
            {
                error && <p className="error-text">
                    Incorrect code entered. Please try again or click the link below to request a new code.
                </p>
            }

            <div style={{ display: "flex", gap: 8 }}>
                <BackButton disabled={submitting} />

                <Button
                    text="Verify Code"
                    width="119px"
                    height="38px"
                    weight="semibold"
                    disabled={otp?.length !== 6 || submitting}
                    loading={submitting}
                />
            </div>
            {(error || otp.length !== 6) &&
                <div className="email-verification-footer">
                    <span>
                        Want a new verification code? <strong
                            onClick={() => {
                                sendEvent({
                                    eventName: isRegisterPage ?
                                        trackEvents.SIGNUP_EMAIL_VERIFICATION_RESEND_BTN :
                                        trackEvents.SIGNIN_EMAIL_VERIFICATION_RESEND_BTN
                                })
                                resendOtpHandler()
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent default action
                                    resendOtpHandler(); // Trigger the click event logic
                                    sendEvent({
                                        eventName: isRegisterPage ?
                                            trackEvents.SIGNUP_EMAIL_VERIFICATION_RESEND_BTN :
                                            trackEvents.SIGNIN_EMAIL_VERIFICATION_RESEND_BTN
                                    })
                                }
                            }}
                            tabIndex={0}
                        >Resend Code</strong>
                    </span>
                </div>
            }
        </form>
    </div>;
};

export default EmailVerification;
