import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, message } from "antd";
import { CloseCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { getPiQTVCategory } from "src/store/piqtv/action";
import VideoList from "./VideoEditPanelConentList";
import useColumnHeader from "src/Hooks/useColumnHeader";
import closeIcon from "src/style/images/close_icon.svg";

import tv_icon from "src/style/images/tv_icon_light.svg";
import tv_icon_dark from "src/style/images/tv_icon_dark.svg";
import { useSelector } from "react-redux";
const VideoEditPanel: React.FC<{
  title: string;
  setShowVideoEditPanel: (params: any) => any;
  socket: string;
}> = ({ setShowVideoEditPanel, socket, }) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const { removeColumn } = useColumnHeader();
  const darkMode = useSelector((state: any) => state?.user?.darkMode);

  useEffect(() => {
    (async () => {
      await getPiQTVCategory().then((res) => {
        setCategory(res.data.data.data);
      });
    })();
  }, [dispatch]);

  return (
    <div className="v2-column-header-option">
      <div style={{ textAlign: "right" }}>
        <Button
          type="text"
          shape="circle"
          size="small"
          className="v2-column-header-option-close-btn"
          icon={
            <img
              style={{
                width: 10,
                height: 10,
                filter: darkMode ? "invert(1)" : "invert(0)",
              }}
              src={closeIcon}
              alt="||"
            />
          }
          onClick={() => setShowVideoEditPanel(false)}
        />
      </div>
      Change Channel
      <img
        src={darkMode ? tv_icon_dark : tv_icon}
        alt=""
        style={{ marginLeft: 5 }}
      />
      <VideoList
        data={category}
        socket={socket}
        closeVideoEditPanel={() => setShowVideoEditPanel(false)}
      />
      <Divider style={{ padding: "0", margin: "10px 0" }} />
      <div
        style={{ color: "red", cursor: "pointer", marginBottom: 5 }}
        onClick={() => removeColumn(socket)}
      >
        <CloseCircleOutlined /> Delete Feed
      </div>
      <div
        style={{ color: "green", cursor: "pointer", marginBottom: 100 }}
        onClick={() => {
          navigator.clipboard.writeText(
            `${window?.location?.origin}/feed/${socket}` || `https://piqsuite.com/feed/${socket}`
          );
          message.success("Link copied to clipboard", 10);
        }}
      >
        <CopyOutlined /> Share Playlist
      </div>
    </div>
  );
};

export default VideoEditPanel;
