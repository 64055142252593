export const KEYWORD_COLOR_LIST = [
    { index: 1, hexcode: "#FFF056", fontColor: "Black" },
    { index: 2, hexcode: "#FF968D", fontColor: "Black" },
    { index: 3, hexcode: "#73FDEA", fontColor: "Black" },
    { index: 4, hexcode: "#88FA4E", fontColor: "Black" },
    { index: 5, hexcode: "#56C1FF", fontColor: "Black" },
    { index: 6, hexcode: "#FF95CA", fontColor: "Black" },
    { index: 7, hexcode: "#FFD932", fontColor: "Black" },
    { index: 8, hexcode: "#FF644E", fontColor: "White" },
    { index: 9, hexcode: "#16E7CF", fontColor: "Black" },
    { index: 10, hexcode: "#61D836", fontColor: "Black" },
    { index: 11, hexcode: "#00A2FF", fontColor: "White" },
    { index: 12, hexcode: "#FF42A1", fontColor: "White" },
    { index: 13, hexcode: "#FEAE00", fontColor: "Black" },
    { index: 14, hexcode: "#EE220C", fontColor: "White" },
    { index: 15, hexcode: "#00AB8E", fontColor: "White" },
    { index: 16, hexcode: "#1DB100", fontColor: "White" },
    { index: 17, hexcode: "#0076BA", fontColor: "White" },
    { index: 18, hexcode: "#D41876", fontColor: "White" },
    { index: 19, hexcode: "#F27200", fontColor: "White" },
    { index: 20, hexcode: "#B51700", fontColor: "White" },
    { index: 21, hexcode: "#006C65", fontColor: "White" },
    { index: 22, hexcode: "#017100", fontColor: "White" },
    { index: 23, hexcode: "#004D80", fontColor: "White" },
    { index: 24, hexcode: "#970E53", fontColor: "White" }
];