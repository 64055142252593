import { useSelector } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";

import { premiumDataDetails } from "src/development/Component/AppSelector/premiumData";
import { useDispatch } from "react-redux";
import { updatePremiumAppData } from "src/store/PremiumApps/premiumAppsSlice";
import { useEffect, useState } from "react";
import { axiosInstance } from "src/store/utility";
import {
    fetchSubscriptionData,
    updateSubscription,
} from "src/store/Subscriptions/subscriptionSlice";
import { check_if_token_is_expired } from "src/store/userStore";
import { popMessage } from "src/development/Component/message/message";
import { useLayoutContext } from "src/context/LayoutProvider";
import { useNavigate } from "react-router-dom";

const useSubscription = () => {
    const [loading, setLoading] = useState(false);
    const [downgradeModalOpen, setDowngradeModalOpen] = useState<boolean>(false);
    const hiq_subscription_data = useSelector((state: any) => state.subscription);
    const has_hiq_subscription = useSelector(
        (state: any) => state?.subscription?.data?.active
    );
    const { setActiveDrawer, activeDrawerState, oldActiveDrawerState } = useLayoutContext()

    const [is_monthly, setIsMonthly] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { newquwak_subscrption_data }: any = useSelector((state: any) => {
        return {
            newquwak_subscrption_data: state.premiumapps.subscribedData,
        };
    });
    const navigate = useNavigate()


    const [subscriptionType, setSubscriptionType] = useState(
        has_hiq_subscription ? "PAID" : "FREE"
    );
    const newsQuawkSubscription =
        newquwak_subscrption_data.find(
            (item: any) => item?.premiumApps?.includes("Newsquawk") && item.active
        ) || {};
    const has_newsquawk_subscription =
        Object.keys(newsQuawkSubscription)?.length > 0 ? true : false;
    const { isSignedIn: isUserLoggedIn } = useAuthContext();
    // const {
    //     activeDrawerState: activeDrawer,
    //     oldActiveDrawerState: oldActiveState,
    // } = props;

    // useEffect(() => {
    //     console.log(oldActiveState, activeDrawer, 'saurav')
    //     if (oldActiveState === "" && activeDrawer === "account-settings-drawer") {
    //         console.log('saurav', 'updated')
    //         dispatch(fetchSubscriptionData());
    //     }
    // }, [activeDrawer, oldActiveState]);

    useEffect(() => {
        if (hiq_subscription_data?.data?.type === "Monthly") {
            setIsMonthly(true);
        } else {
            setIsMonthly(false);
        }
    }, [hiq_subscription_data?.data?.type, activeDrawerState]);

    const handleBuyNow = () => {
        if (!isUserLoggedIn) {
            navigate("/login");
            return;
        }
        const updatedPremiumData: any = premiumDataDetails.map((data: any) =>
            data.title === "Newsquawk" ? { ...data, checked: true } : data
        );
        dispatch(updatePremiumAppData(updatedPremiumData));
        setActiveDrawer("upgrade-premium-app");
    };

    const handleDowngrade = async () => {
        try {
            setLoading(true);
            await axiosInstance.post(`subscriptions/delete`);
            dispatch(updateSubscription());
            setLoading(false);
        } catch (error: any) {
            dispatch(check_if_token_is_expired(error?.response));
            popMessage.errorMessage("Something Went Wrong");
            setLoading(false);
        }
        setDowngradeModalOpen(false);
    };

    return {
        has_newsquawk_subscription,
        handleBuyNow,
        handleDowngrade,
        loading,
        downgradeModalOpen,
        setDowngradeModalOpen,
        is_monthly,
        setIsMonthly,
        subscriptionType,
        setSubscriptionType,
        hiq_subscription_data,
        has_hiq_subscription,
    };
};

export default useSubscription;
