import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteModal: false,
};

export const feedSharingSlice = createSlice({
  name: "feedsharing",
  initialState,
  reducers: {
    updatetrueFeedDeleteModal: (state: any) => {
      state.deleteModal = true;
    },
  },
});

export const { updatetrueFeedDeleteModal } = feedSharingSlice.actions;
export default feedSharingSlice.reducer;
