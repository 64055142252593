import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { feedAxiosInstance } from "../utility";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

export const fetchInFeedAdsData: any = createAsyncThunk(
  "infeedads/infeedadsData",
  async () => {
    const res = await feedAxiosInstance.get(`infeedads/list/w`);
    return res.data.data;
  }
);

export const inFeedAdsSlice = createSlice({
  name: "infeedads",
  initialState,
  reducers: {
    updateInFeedAdsData: (state: any, action: any) => {
      state.data = action.payload;
    },
    resetInFeedAdsData: (state: any, action: any) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInFeedAdsData.pending, (state) => {
        // state.data = premiumDataDetails
        state.loading = true;
      })
      .addCase(fetchInFeedAdsData.fulfilled, (state, action) => {
        // console.log(action?.payload, "piqcolumns");
        state.data = action?.payload;

        state.loading = false;
      })
      .addCase(fetchInFeedAdsData.rejected, (state, action) => {
        state.data = [];
        state.error = "Something went wrong";
        state.loading = false;
      });
  },
});

export const { updateInFeedAdsData, resetInFeedAdsData } =
  inFeedAdsSlice.actions;
export default inFeedAdsSlice.reducer;
