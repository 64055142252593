import * as actionTypes from "./actionType";
import { axiosInstance } from "../utility";

export const piqvideofeedGetStart = () => {
  return {
    type: actionTypes.PIQVIDEOFEED_GET_START,
  };
};

export const piqvideofeedGetSuccess = (piqvideofeed: any) => {
  return {
    type: actionTypes.PIQVIDEOFEED_GET_SUCCESS,
    piqvideofeed: piqvideofeed,
  };
};

export const piqvideofeedGetFail = (error: any) => {
  return {
    type: actionTypes.PIQVIDEOFEED_GET_FAIL,
    error: error,
  };
};

export const piqvideofeedDetailStart = () => {
  return {
    type: actionTypes.PIQVIDEOFEED_DETAIL_START,
  };
};

export const piqvideofeedDetailSuccess = (piqvideofeedDetail: any) => {
  return {
    type: actionTypes.PIQVIDEOFEED_DETAIL_SUCCESS,
    piqvideofeedDetail: piqvideofeedDetail,
  };
};

export const piqvideofeedDetailFail = (error: any) => {
  return {
    type: actionTypes.PIQVIDEOFEED_DETAIL_FAIL,
    error: error,
  };
};

export const setPiQVideoFeed = (videoFeed: any) => {
  return {
    type: actionTypes.SET_PIQ_VIDEO_FEED,
    videoFeed: videoFeed,
  };
};

export const getpiqvideofeed = (
  params = {},
  onSuccess: any = null,
  onError: any = null
) => {
  return (dispatch: any) => {
    dispatch(piqvideofeedGetStart());
    dispatch(piqvideofeedGetSuccess([]));
    axiosInstance
      .get(`/piqtv/public`, { params })
      .then((res) => {
        dispatch(piqvideofeedGetSuccess(res.data));
        if (typeof onSuccess == "function") {
          onSuccess(res);
        }
      })
      .catch((err) => {
        dispatch(piqvideofeedGetFail(err));
        if (typeof onError == "function") {
          onError(err);
        }
      });
  };
};

export const getPiQTVCategory = () => {
  return axiosInstance.get(`/piqtvcategory`);
};
export const getpiqvideofeedBySlug = (slug: string) => {
  return axiosInstance.get(`/piqtv/slug/${slug}`);
};

export const getDetailpiqvideofeed = (
  id: any,
  onSuccess: any = null,
  onError: any = null
) => {
  return (dispatch: any) => {
    dispatch(piqvideofeedDetailStart());
    axiosInstance
      .get(`/api/v1/piqvideofeed/${id}/`)
      .then((res) => {
        dispatch(piqvideofeedDetailSuccess(res.data));
        if (typeof onSuccess == "function") {
          onSuccess(res);
        }
      })
      .catch((err) => {
        dispatch(piqvideofeedDetailFail(err));
        if (typeof onError == "function") {
          onError(err);
        }
      });
  };
};
