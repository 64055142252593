import React from "react";

import './AppliedPromoCard.scss'
import { Icon } from "@iconify/react";
import { monthlyHighIqPrice, monthlySalesTax, annuallyHighIqPrice, annualSalesTax } from "src/constants/pricing";

type Props = {
    promocode: string;
    discountPercent?: number;
    discountAmount: number;
    isMonthly: boolean;
};

const AppliedPromoCard = ({
    promocode,
    discountAmount,
    discountPercent,
    isMonthly
}: Props) => {
    return <div className="applied-promo-card">
        <Icon icon="pajamas:check-circle-filled" fontSize={16} />
        <p>
            <strong>{promocode}</strong>
            <span>
                {!!discountPercent && `${discountPercent}% discount`} (-£{discountAmount})
            </span>
        </p>
    </div>;
};

export default AppliedPromoCard;
