import React, { useEffect, useState, useMemo } from "react";
import { Button, Checkbox } from "antd";
import { useSelector } from "react-redux";
import useSubscription from "src/Hooks/useSubscription";

const CustomFeedAdd: React.FC<{
  socket: string;
  handleAddCustomFeed: (socket: string, slugs: string[]) => void;
  setGenerateKey: React.Dispatch<React.SetStateAction<number>>;
  generateKey: number;
}> = ({ socket, handleAddCustomFeed, setGenerateKey, generateKey }) => {
  const [slugs, setSlugs] = useState<string[]>([]);
  const { hasNewsQuawkSubscription, hasHammerstoneSubscription }: any = useSelector(
    (state: any) => state.premiumapps
  );
  const { customFeeds, darkMode, columns } = useSelector((state: any) => ({
    customFeeds: state.piqcolumns.customFeeds,
    darkMode: state.user.darkMode,
    columns: state.piqcolumns.columns,
  }));

  const excludedSlugs = useMemo(() => [
    "tradingview",
    "investing-economic-calender",
    "piqpodcast",
    "telegram",
    ...(hasNewsQuawkSubscription ? [] : ["newsquawk"]),
    ...(hasHammerstoneSubscription ? [] : ["hammerstone"]),
  ], [hasNewsQuawkSubscription, hasHammerstoneSubscription]);
  const excludedFeeds = useMemo(() => [
    "PIQ_FEED",
    "TUTORIAL_FEED",
    "TWITTER_FEED",
    "PREDICTION_MARKETS",
    "CHART_FEED"
  ], []);

  const options = useMemo(() =>
    columns
      ?.filter((item: any) => !excludedSlugs.includes(item?.slug))
      ?.filter((item: any) => !excludedFeeds.includes(item?.appSelectorTypes))
      .map((data: any) => ({
        label: data.title,
        value: data.slug
      }))
      .sort((a: any, b: any) => a.label.localeCompare(b.label)),
    [columns, excludedSlugs, excludedFeeds]
  );
  useEffect(() => {
    const feedToEdit = customFeeds.find((feed: any) => feed.slug === socket);
    if (feedToEdit) {
      setSlugs(feedToEdit.slugs);
      // setGenerateKey(Math.random());
    }
  }, [customFeeds, socket, setGenerateKey]);

  useEffect(() => {
    setGenerateKey(Math.random());
  }, []);

  const onChange = (checkedValues: string[]) => {
    setSlugs(checkedValues);
    handleAddCustomFeed(socket, checkedValues)
  };

  return (
    <div className={darkMode ? "dark-custom-add-feed-main-container" : "custom-add-feed-main-container"}>
      <div>{slugs.length} Selected</div>
      <div className="feed-lists">
        <Checkbox.Group
          options={options}
          defaultValue={slugs}
          onChange={(event) => { onChange(event as string[]) }}
          key={generateKey}
        />
      </div>
      {/* <div className={slugs.length === 0 ? "disabled" : ""}>
        <Button
          type="primary"
          onClick={(e) => handleAddCustomFeed(e, socket, slugs)}
          disabled={slugs.length === 0}
        >
          Update
        </Button>
      </div> */}
    </div>
  );
};

export default React.memo(CustomFeedAdd);
