import { PiqState } from "../../interface/PiqState";

export const initialState: PiqState = {
  publicTweets: [],
  economicFeeds: [],
  personalTweets: [],
  feedlyTweets: [],
  publicCollections: [],
  collectionNames: [],
  morningRambles: [],
  brexit: {},
  market: {},
  politics: {},
  //Partner Feed Start
  emergingMarket: {},
  capital: {},
  forexlive: {},
  harkster: {},
  reuters: {},
  reutersDetails: null,
  econostream: {},
  mtsinsights: {},
  audiomercados: {},
  //Partner Feed Start
  economy: {},
  crypto: {},
  allNews: {},
  chinaNews: {},
  sportsNews: {},
  creditNews: {},
  cryptoTimes: {},
  tickerApp: {},
  politicsUS: {},
  politicsUK: {},
  politicsEU: {},
  commodities: {},
  energyNews: {},
  FXNews: {},
  stocksNews: {},
  vendor: [],

  //Twitter Lists start
  cryptoTweets: {},
  geopoliticsTweets: {},
  uspoliticsTweets: {},
  ukpoliticsTweets: {},
  weatherTweets: {},
  marketTweets: {},
  squawksTweets: {},
  newsquawk: {},
  //Twitter Lists end

  selectionCollection: [
    "user/57cdca64-5d53-4dff-b877-48073af79af0/priority/b3fca5a1-84dd-4e9a-b7cc-672da8d4ed8f",
  ],
  archive: [],
  priaData: {},
  windowSize: [],
  activeDrawer: "",
};

export const PIQReducer = (state: PiqState = initialState, action: any) => {
  switch (action?.type) {
    case "LOAD_FEED_DATA":
      return {
        ...state,
        [action.reducer]: action.payload,
      };
    case "GET_PUBLIC_TWEETS_SUCCESS":
      return {
        ...state,
        publicTweets: action.payload,
      };
    case "GET_ECONOMIC_FEEDS_SUCCESS":
      return {
        ...state,
        economicFeeds: action.payload,
      };
    case "GET_PERSONAL_TWEETS_SUCCESS":
      return {
        ...state,
        personalTweets: action.payload,
      };
    case "RETWEET":
      const currentTweetIndex = state.personalTweets.findIndex(
        (tweet) => tweet.id_str === action.payload
      );
      const currentTweetObject = state.personalTweets.filter(
        (tweet) => tweet.id_str === action.payload
      );
      const currentPersonalTweet = {
        ...currentTweetObject[0],
        retweet_count: ++currentTweetObject[0].retweet_count,
      };
      return {
        ...state,
        personalTweets: [
          ...state.personalTweets.slice(0, currentTweetIndex),
          currentPersonalTweet,
          ...state.personalTweets.slice(currentTweetIndex + 1),
        ],
      };
    case "FAVOURITE":
      const tweetIndex = state.personalTweets.findIndex(
        (tweet) => tweet.id_str === action.payload
      );
      const tweetObject = state.personalTweets.filter(
        (tweet) => tweet.id_str === action.payload
      );
      const personalTweet = {
        ...tweetObject[0],
        favorite_count: ++tweetObject[0].favorite_count,
      };
      return {
        ...state,
        personalTweets: [
          ...state.personalTweets.slice(0, tweetIndex),
          personalTweet,
          ...state.personalTweets.slice(tweetIndex + 1),
        ],
      };
    case "GET_FEEDLY_TWEETS_SUCCESS":
      return {
        ...state,
        feedlyTweets: action.payload,
      };
    case "GET_COLLECTIONS_SUCCESS":
      return {
        ...state,
        collectionNames: action.payload,
      };
    case "SET_COLLECTION":
      return {
        ...state,
        selectionCollection: action.payload,
      };
    case "GET_RAMBLES_SUCCESS":
      return {
        ...state,
        morningRambles: action.payload,
      };
    case "GET_ALL_NEWS_SUCCESS":
      return {
        ...state,
        allNews: action.payload,
      };
    case "GET_CREDIT_NEWS_SUCCESS":
      return {
        ...state,
        creditNews: action.payload,
      };
    case "GET_BREXIT_SUCCESS":
      return {
        ...state,
        brexit: action.payload,
      };
    case "GET_ECONOMIC_SUCCESS":
      return {
        ...state,
        economy: action.payload,
      };
    case "GET_CRYPTO_SUCCESS":
      return {
        ...state,
        crypto: action.payload,
      };
    case "GET_MARKET_SUCCESS":
      return {
        ...state,
        market: action.payload,
      };
    case "GET_POLITICS_SUCCESS":
      return {
        ...state,
        politics: action.payload,
      };
    //new news cols
    case "GET_POLITICS_US_SUCCESS":
      return {
        ...state,
        politicsUS: action.payload,
      };
    case "GET_POLITICS_UK_SUCCESS":
      return {
        ...state,
        politicsUK: action.payload,
      };
    case "GET_POLITICS_EU_SUCCESS":
      return {
        ...state,
        politicsEU: action.payload,
      };
    case "GET_ENERGY_SUCCESS":
      return {
        ...state,
        energyNews: action.payload,
      };
    case "GET_CHINA_NEWS_SUCCESS":
      return {
        ...state,
        chinaNews: action.payload,
      };
    case "GET_SPORTS_SUCCESS":
      return {
        ...state,
        sportsNews: action.payload,
      };
    case "GET_FX_NEWS_SUCCESS":
      return {
        ...state,
        FXNews: action.payload,
      };
    case "GET_COMMODITIES_SUCCESS":
      return {
        ...state,
        commodities: action.payload,
      };
    case "GET_STOCKS_NEWS_SUCCESS":
      return {
        ...state,
        stocksNews: action.payload,
      };
    case "GET_VENDOR_NEWS_SUCCESS":
      return {
        ...state,
        vendor: [...action.payload],
      };
    case "GET_ARCHIVE_SUCCESS":
      return {
        ...state,
        archive: action.payload,
      };
    case "SET_PRIA_COLUMN_LIST":
      return {
        ...state,
        columnList: action.payload,
      };
    case "SET_REUTERS_DATA_SUCCESS":
      return {
        ...state,
        reuters: action.payload,
      };
    case "SET_AUDIOMERCADOS_DATA_SUCCESS":
      return {
        ...state,
        audiomercados: action.payload,
      };
    case "GET_EMERGING_MARKET_SUCCESS":
      return {
        ...state,
        emergingMarket: action.payload,
      };
    case "SET_CAPITAL_DATA":
      return {
        ...state,
        capital: action.payload,
      };
    case "SET_FOREXLIVE_DATA":
      return {
        ...state,
        forexlive: action.payload,
      };
    case "SET_HARKSTER_DATA":
      return {
        ...state,
        harkster: action.payload,
      };
    case "SET_ECONOSTREAM_DATA":
      return {
        ...state,
        econostream: action.payload,
      };
    case "SET_MTSINSIGHTS_DATA":
      return {
        ...state,
        mtsinsights: action.payload,
      };
    case "SET_REUTERS_DETAILS":
      return {
        ...state,
        reutersDetails: action.payload,
      };
    case "GET_CRYPTO_TWEETS_SUCCESS":
      return {
        ...state,
        cryptoTweets: action.payload,
      };
    case "GET_GEOPOLITICS_TWEETS_SUCCESS":
      return {
        ...state,
        geopoliticsTweets: action.payload,
      };
    case "GET_MARKET_TWEETS_SUCCESS":
      return {
        ...state,
        marketTweets: action.payload,
      };
    case "GET_ECONOMIC_TWEETS_SUCCESS":
      return {
        ...state,
        geopoliticsTweets: action.payload,
      };
    case "GET_SQUAWKS_TWEETS_SUCCESS":
      return {
        ...state,
        squawksTweets: action.payload,
      };
    case "GET_UKPOLITICS_TWEETS_SUCCESS":
      return {
        ...state,
        ukpoliticsTweets: action.payload,
      };
    case "GET_USPOLITICS_TWEETS_SUCCESS":
      return {
        ...state,
        uspoliticsTweets: action.payload,
      };
    case "GET_WEATHER_TWEETS_SUCCESS":
      return {
        ...state,
        weatherTweets: action.payload,
      };
    case "SET_WINDOW_SIZE":
      return {
        ...state,
        windowSize: action.payload,
      };
    case "SET_ACTIVE_DRAWER":
      let oldState = state.activeDrawer;
      let newState = action.payload;

      if (oldState === "" && newState === "article-list-drawer") {
        const existingDev = document.getElementById("ic_d_div_OOP_1");
        if (existingDev) {
          window.removeAds();
        }

        // const div = document.getElementById(
        //   "ic_d_div_728x90_1"
        // ) as HTMLDivElement;
        // window.createNewPage();
      } else if (
        newState === "article-list-drawer" &&
        oldState === "article-details-drawer"
      ) {
        window.removeAds();
      } else if (oldState === "article-list-drawer" && newState === "") {
        window.removeAds();
        window.createNewPage();
      } else if (
        oldState === "article-details-drawer" &&
        newState === "article-list-drawer"
      ) {
        // window.removeAds();
        // window.history.pushState({}, "", "/");
      } else if (
        oldState === "article-list-drawer" &&
        newState === "article-details-drawer"
      ) {
        // window.removeElement("ic_d_div_728x90_1");
        window.removeAds();
      } else if (newState === "article-details-drawer" && oldState === "") {
        // try {
        //   window.removeAds();
        // } catch (e) {
        //   console.log("failed to remove since no ads");
        // }
        // window.createNewPage();
      }

      return {
        ...state,
        activeDrawer: action.payload,
      };
    case "SET_PRIA_DATA":
      let dataList = {
        ...state.priaData,
        [action.priaType]: action.payload,
      };

      return {
        ...state,
        priaData: dataList,
      };
    default:
      return state;
  }
};
