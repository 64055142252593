import React, { useRef } from "react";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import { useState } from "react";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import { SocketNames } from "src/interface/PiqState";
import useFeeds from "src/Hooks/useFeeds";
import { useSelector } from "react-redux";
import CmeGroupContent from "./CmeGroupContent";

export const CmeGroupApp: React.FC<{
    socket: SocketNames;
    inFeedSharingModal?: boolean;
}> = ({ socket, inFeedSharingModal = false }) => {

    const [title, setTitle] = useState<string>("")
    const [link, setLink] = useState<string>("")
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(false);
    const { getColumnDetail } = useFeeds()

    const darkMode = useSelector((state: any) => state?.user?.darkMode);
    const column = getColumnDetail(socket)

    const refresh_function = () => {
        if (!iframeRef?.current?.src) return;
        setFetching(true);
        if (iframeRef?.current) {
            iframeRef.current.src = iframeRef.current.src;
        }
    };

    return (
        <>
            {
                !inFeedSharingModal && <ColumnHeader
                    socket={socket}
                    isFetching={isFetching}
                    fetchFunction={() => refresh_function()}
                    title={column?.title}
                    handleEditPanel={() => setShowEditPanel(true)}
                />
            }
            <div
                className={`cmegroup-app-container ${darkMode && !inFeedSharingModal ? "dark" : ""}`}
                style={{
                    height: "calc(100% - 26px)",
                    border: `${inFeedSharingModal ? "none" : ""}`,
                }}
            >
                {showEditPanel && socket ? (
                    <ColumnHeaderOption
                        setShowEditPanel={setShowEditPanel}
                        socket={socket}
                    />
                ) : (
                    <div className="main-area no-scrollbar">
                        <CmeGroupContent
                            link={link}
                            title={title}
                            setLink={setLink}
                            setTitle={setTitle}
                            setFetching={setFetching}
                            fetching={isFetching}
                            ref={iframeRef}
                            inFeedSharingModal={inFeedSharingModal}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
