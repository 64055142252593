import "./LoginPage.scss";
import React, { useState } from "react";
import { useLogin } from "src/Hooks/auth/useNewLogin";
import LoginForm from "./LoginForm";
import EmailVerification from "../EmailVerification/EmailVerification";
import EmailVerified from "../SuccessComponent/EmailVerified";
import { addUrlParam } from "src/utils/utilFunction";
import { useLocation, useNavigate } from "react-router-dom";
import Checkout from "../Checkout/Checkout";
import { useAuthEmailVerification } from "src/Hooks/auth/useAuthEmailVerification";
import PaymentSuccessful from "../SuccessComponent/PaymentSuccessful";
import { TLoginSchema } from "src/schema/auth/auth.schema";
import { UseFormReturn } from "react-hook-form";

const LoginPage = () => {
  const [promoCodeError, setPromoCodeError] = useState<string>("");
  const [promoCode, setPromoCode] = useState<string>("");

  const {
    form,
    error,
    internalStep,
    setInternalStep,
    currentStep,
    handleEmailSubmit,
    handleLogin,
  } = useLogin({ promoCode });

  const {
    handleResendEmailVerificationButton,
    emailOtpSubmitting,
    otpSubmitHandler,
    otpError,
    setOtpError,
  } = useAuthEmailVerification({
    withCheckout: true,
    nextStep: () => setInternalStep(3),
    email: localStorage.getItem('loginEmail') || ''
  });

  const navigate = useNavigate()
  const location = useLocation();

  if (currentStep === "3") {
    return <Checkout nextStepHandler={() => {
      const nextUrl = addUrlParam("step", "4");
      navigate(location.pathname + "?" + nextUrl);
    }} />;
  }

  if (currentStep === "4") {
    return <PaymentSuccessful />;
  }

  return (
    <div className="login-page-wrapper">
      <div className="login-page-container">
        {currentStep === "1" ? (
          <>
            {internalStep === 1 ? (
              <LoginForm
                emailError={error}
                form={form as UseFormReturn<TLoginSchema>}
                showPasswordFields={false}
                onSubmit={handleEmailSubmit}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                promoCodeError={promoCodeError}
                setPromoCodeError={setPromoCodeError}
              />
            ) : internalStep === 2 ? (
              <EmailVerification
                nextStep={() => setInternalStep(3)}
                nextStepHandler={otpSubmitHandler}
                resendOtpHandler={handleResendEmailVerificationButton}
                submitting={emailOtpSubmitting}
                error={!!otpError}
                email={form.getValues("email") || ""}
                setError={setOtpError}
                sendOtpByDefault
              />
            ) : internalStep === 3 ? (
              <EmailVerified
                nextHandler={() => {
                  const nextUrl = addUrlParam("step", "2");
                  navigate(location.pathname + "?" + nextUrl);
                }}
              />
            ) : internalStep === 4 ?
              <LoginForm
                form={form as UseFormReturn<TLoginSchema>}
                showPasswordFields={true}
                onSubmit={handleLogin}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                promoCodeError={promoCodeError}
                setPromoCodeError={setPromoCodeError}
              />
              : null
            }
          </>
        ) : currentStep === "2" ? (
          <LoginForm
            form={form as UseFormReturn<TLoginSchema>}
            showPasswordFields={true}
            onSubmit={handleLogin}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            promoCodeError={promoCodeError}
            setPromoCodeError={setPromoCodeError}
          />
        ) : null}
      </div>
    </div>
  );
};

export default LoginPage;