import sandconsolebanner1 from "src/style/images/sandconsole1.svg";
import sandconsolebanner2 from "src/style/images/sandconsole2.svg";
import sandconsolebanner3 from "src/style/images/sandconsole3.svg";
import sandconsolefeed1 from "src/style/images/sand_feed1.svg";
import sandconsolefeed2 from "src/style/images/sand_feed2.png";
import consusbannerads from "src/style/images/consus_banner_ad.png";
export const STATIC_BANNER_ADS = {
  "sand-console": [
    {
      title: "Sand Console",
      image: sandconsolebanner1,
      url: "https://sandconsole.com",
    },
    {
      title: "Sand Console",
      image: sandconsolebanner2,
      url: "https://sandconsole.com",
    },
    {
      title: "Sand Console",
      image: sandconsolebanner3,
      url: "https://sandconsole.com",
    },
  ],
  pepperstone: [
    {
      title: "Consus",
      image: consusbannerads,
      url: "https://www.consusag.net",
    },
  ],
};

export const getRandomStaticAds = () => {
  const keys = Object.keys(STATIC_BANNER_ADS);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  const randomAdsArray =
    STATIC_BANNER_ADS[randomKey as keyof typeof STATIC_BANNER_ADS];
  const randomIndex = Math.floor(Math.random() * randomAdsArray.length);
  return randomAdsArray[randomIndex];
};

export const STATIC_INFEED_ADS = {
  "sand-console": [
    {
      title: "Sand Console",
      image: sandconsolefeed1,
      url: "https://sandconsole.com",
    },
    {
      title: "Sand Console",
      image: sandconsolefeed2,
      url: "https://sandconsole.com",
    },
  ],
};

export const getRandomStaticInfeedAds = () => {
  const keys = Object.keys(STATIC_INFEED_ADS);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  const randomAdsArray =
    STATIC_INFEED_ADS[randomKey as keyof typeof STATIC_INFEED_ADS];
  const randomIndex = Math.floor(Math.random() * randomAdsArray.length);
  return randomAdsArray[randomIndex];
};
