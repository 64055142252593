import React from "react";
import { useSelector } from "react-redux";

function GlobalSearchLoading() {
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));

  const skeletonArray = new Array(6).fill(null);

  return (
    <>
      {skeletonArray.map((data, i) => {
        return (
          <div
            className={
              darkMode
                ? "dark_loading_card_container"
                : "loading_card_container"
            }
            key={i}
          >
            <div className="loading_second_line"></div>

            <div className="loading_card_top_container">
              <div className="dummy_img"></div>
              <div className="right">
                <div className="source_topic"></div>
                <div className="date_time"></div>
              </div>
              <div className="left">
                <div className="box"></div>
                <div className="box"></div>
                <div className="box"></div>
              </div>
            </div>
            <div className="loading_main_title"></div>
          </div>
        );
      })}
    </>
  );
}

export default GlobalSearchLoading;
