import React from "react";
import { useSelector } from "react-redux";

import menyIcon from "src/style/images/Menu Icon.svg";

import { Col, Row } from "antd";
import { RefreshButtoneComponent } from "../RefreshButton/inde";
import { useAuthContext } from "src/context/AuthProvider";

const VideoColumnHeader: React.FC<{
  navImg?: any;
  title: string;
  isFetching?: boolean;
  fetchFunction?: () => any;
  handleVideoEditPanel?: (params: any) => any;
}> = ({ title, handleVideoEditPanel, isFetching, fetchFunction }) => {
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const { isSignedIn: isUserLoggedIn } = useAuthContext();

  const handelReload = () => {
    fetchFunction && fetchFunction()
  }
  return (
    <div className="v2-column-header" style={{ borderBottom: "2px solid rgb(160, 160, 160)" }}>
      <div
        className="pointer title-container"

      >
        <div className="pointer">
          <span className="v2-column-header-text grid-drag-handle">
            {isUserLoggedIn ? title : "PiQ TV" || "Loading..."}
          </span>
        </div>
        <div className="pointer">
          <RefreshButtoneComponent
            fetchFunction={() => handelReload()}
            isFetching={isFetching}
          />
        </div>
        <div className="grid-drag-handle pointer">
          <div></div>
        </div>
      </div>
      <div className="grid-drag-handle pointer filler" >
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          // justifyContent: "end",
          alignItems: "center",
          gap: "6px",
        }}
        className=""
      >
        <div className="grid-drag-handle pointer filler"></div>
        <div onClick={handleVideoEditPanel}
          className="pointer"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px"
          }}>
          <img
            src={menyIcon}
            alt="||"
            className={`v2-column-header-option-icon ${darkMode ? "inverted" : ""}`}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoColumnHeader;
