import React, { Children } from 'react';
import { TwitterIcon, XIcon } from 'react-share'; // Using just the icon for visual consistency with react-share

interface CustomTwitterShareButton {
    title?: string;
    hashtags?: string[];
    via?: string;
    related?: string[];
    className?: string;
    iconSize?: number;
    url?: string;
    children: React.ReactNode;
}

const CustomTwitterShareButton: React.FC<CustomTwitterShareButton> = ({
    title,
    hashtags,
    via,
    related,
    className,
    iconSize = 32,
    url,
    children
}) => {
    const handleShare = () => {
        const twitterBaseUrl = "https://twitter.com/intent/tweet?";
        const queryParams = new URLSearchParams({
            text: title || "Sharing to Twitter!",
            ...(hashtags ? { hashtags: hashtags.join(',') } : {}),
            ...(via ? { via } : {}),
            ...(related ? { related: related.join(',') } : {}),
            ...(url ? { url } : {})
        });
        const fullUrl = twitterBaseUrl + queryParams.toString();

        window.open(fullUrl, 'twitter-share', 'width=600,height=400,noopener,noreferrer');
    };

    return (
        <span onClick={handleShare} >
            {children}
        </span>
    );
};

export default CustomTwitterShareButton;