import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { feedAxiosInstance } from "../utility";

export interface PiqNewsFeedState {
  error: string;
  loading: boolean;
  piqNewsFeed: any[];
  page: number;
  limit: number;
  total: number;
  hasMore: boolean;
}

const initialState: PiqNewsFeedState = {
  error: "",
  loading: false,
  piqNewsFeed: [],
  page: 1,
  limit: 2,
  total: 0,
  hasMore: true,
};

export const fetchPiqNewsFeed = createAsyncThunk("piqNewsFeed", async () => {
  const res = await feedAxiosInstance.get(
    "piq-columns/w?appSelectorTypes=PARTNER_FEED&page=1&limit=10"
  );
  return res.data;
});

export const fetchNextPiqNewsFeed = createAsyncThunk(
  "piqNewsFeed/next",
  async (_, { getState }) => {
    const state = getState() as { piqNewsFeed: PiqNewsFeedState };
    const { page, limit, hasMore } = state.piqNewsFeed;

    if (!hasMore) return null;

    const nextPage = page + 1;
    const res = await feedAxiosInstance.get(
      `piq-columns/w?appSelectorTypes=PARTNER_FEED&page=${nextPage}&limit=${limit}`
    );
    return res.data;
  }
);

export const piqNewsFeedSlice = createSlice({
  name: "piqNewsFeed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPiqNewsFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPiqNewsFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.piqNewsFeed = action.payload.data;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.total = action.payload.total;
      state.hasMore = state.piqNewsFeed.length < action.payload.total;
    });
    builder.addCase(fetchPiqNewsFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message ?? "";
      state.piqNewsFeed = [];
      state.hasMore = false;
    });
    builder.addCase(fetchNextPiqNewsFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNextPiqNewsFeed.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.loading = false;
      state.error = "";
      state.piqNewsFeed = [...state.piqNewsFeed, ...action.payload.data];
      state.page = action.payload.page;
      state.hasMore = state.piqNewsFeed.length < action.payload.total;
    });
    builder.addCase(fetchNextPiqNewsFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message ?? "";
      state.hasMore = false;
    });
  },
});

export const {} = piqNewsFeedSlice.actions;
export default piqNewsFeedSlice.reducer;
