import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SocialMediaShare } from "../socialMediaShare";
import moment from "moment";
import useFeeds from "src/Hooks/useFeeds";
import { useSelector } from "react-redux";
import { getUrlToShare } from "src/utils/common";

interface BookmarkCardProps {
    data: any;
    props: any;
    bookmarkClickHandler: (props: any) => void;
    setShowBookmark: Dispatch<SetStateAction<boolean>>;
}

function BookmarkCard({ data, props, bookmarkClickHandler, setShowBookmark }: BookmarkCardProps) {
    const [img, setImg] = useState("");
    const [title, setTitle] = useState("");
    const [socket, setSocket] = useState("");
    const [twitterAccount, setTwitterAccount] = useState("");

    const darkMode = useSelector((state: any) => state?.user?.darkMode)


    const Columns = useSelector((state: any) => {
        return state?.piqcolumns?.columns;
    });
    const getDate = moment(data?.pubDate).format("MMM Do, HH:mm:ss");


    const handleDetailsPage = () => {
        handleShowDetials(data, data?.sourceName, setShowBookmark);

    };
    const { handleShowDetials, handleCopyLink, handleSocialMediaShareData } =
        useFeeds(props);

    useEffect(() => {
        const fieldData = Columns.find(
            (item: any) => item.slug === data?.sourceName
        );
        if (fieldData) {
            setTitle(fieldData?.title);
            setImg(fieldData?.icon);
            setTwitterAccount(fieldData?.twitterAccount);
            setSocket(fieldData?.slug);
        }
    }, [data]);

    return (
        <div className="card_container">
            <div className="card_top_container">
                <div className="title_img">
                    <img src={img} alt="logo"></img>
                </div>
                <div className="right">
                    <div className="left">
                        <div className="source_topic">{title}</div>
                        <SocialMediaShare
                            data={handleSocialMediaShareData(
                                data,
                                socket as any,
                                twitterAccount
                            )}
                            showShareIcon={true}
                            url={"\n\nFull Story → " + getUrlToShare(data, socket as any)}
                            onCopyLink={() => handleCopyLink(data, socket as any)}
                            article_id={data?.article_id ?? data?._id}
                            bookmarkClickHandler={(e) => {
                                e.stopPropagation();
                                bookmarkClickHandler(data)
                            }}
                        ></SocialMediaShare>
                    </div>
                    <div className="date_time">{getDate}</div>
                </div>
            </div>
            <div className="main_title" onClick={handleDetailsPage}>
                {data?.title}
            </div>
        </div>
    );
}

export default BookmarkCard;
