import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import TextInput from "../../TextInput/TextInput";
import Button from "../../Button/Button";
import { Checkbox } from "antd";
import useCheckout from "src/Hooks/auth/useCheckout";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useSelector } from "react-redux";
import { annuallyHighIqPrice, annualSalesTax, monthlyHighIqPrice, monthlySalesTax } from "src/constants/pricing";
import AppliedPromoCard from "./AppliedPromoCard";
import { debounce } from "lodash";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import BackButton from "../BackButton";

type Props = {
    monthly: boolean,
    nextStepHandler: () => void;
    paymentError: boolean;
    setPaymentError: Dispatch<SetStateAction<boolean>>
}

export const CheckoutForm = ({ monthly, nextStepHandler, paymentError, setPaymentError }: Props) => {
    const elements = useElements();
    const stripe = useStripe();

    const lm_data = useSelector((state: any) => state?.linkmink?.data.lm_data);
    const { userData } = useSelector((state: any) => ({
        userData: state?.user_data?.data,
    }));

    const { promoCodeSubmitHandler,
        promoCodeRef,
        promoCodeError,
        promoCodeSubmitting,
        paymentSubmitHandler,
        tc,
        setTc,
        paymentLoading,
        discountAmount,
        discountPercent,
        promoCode,
        setPromoCode,
        setOriginalDiscountPercent,
        originalDiscountPercent,
        setDiscountAmount
    } = useCheckout({
        elements,
        lm_data,
        stripe,
        userData,
        setPaymentError,
        afterPaymentStepHandler: nextStepHandler,
        isMonthly: monthly
    });

    const { sendEvent } = useTrackerContext();

    const promoCodeChangeHandler = debounce(() => {
        sendEvent({
            eventName: trackEvents.HIGH_IQ_PRMO_CODE_WRITE,
            payload: {
                code: promoCodeRef?.current?.value
            }
        })
    }, 500)

    useEffect(() => {
        if (originalDiscountPercent > 0) {
            const basePrice = monthly ? monthlyHighIqPrice : annuallyHighIqPrice;
            const newDiscountAmount = (basePrice * originalDiscountPercent) / 100;
            setDiscountAmount(newDiscountAmount);
        }
    }, [monthly, originalDiscountPercent]);

    const calculateTotal = () => {
        const basePrice = monthly ? monthlyHighIqPrice : annuallyHighIqPrice;
        const salesTax = monthly ? monthlySalesTax : annualSalesTax;
        const totalBeforeDiscount = basePrice + salesTax;

        const discountedAmount = originalDiscountPercent > 0
            ? (totalBeforeDiscount * originalDiscountPercent) / 100
            : (discountAmount || 0);

        const total = totalBeforeDiscount - discountedAmount;
        return Number(total.toFixed(2));
    };

    return (
        <form action="" className="checkout-form" onSubmit={(e) => paymentSubmitHandler(e, promoCode)}>
            <div className="checkout-form__container">
                <div>
                    <PaymentElement
                        onChange={(e) => {
                            if (e.complete) {
                                setPaymentError(false)
                            }
                        }}
                        options={{
                        }}
                    ></PaymentElement>
                </div>
                <div className="bill">
                    <form action="#" className="bill__header" onSubmit={(e) => {
                        e.preventDefault(); // Prevent the outer form from submitting
                        if (promoCodeRef?.current?.value !== "") {
                            promoCodeSubmitHandler(e);
                        }
                    }}>
                        <label>
                            Promo Code
                        </label>

                        {
                            !!discountAmount ? <div className="applied-promo-card-wrapper">
                                <AppliedPromoCard
                                    isMonthly={monthly}
                                    promocode={promoCode}
                                    discountAmount={discountAmount}
                                    discountPercent={discountPercent}
                                />
                            </div> : <TextInput
                                placeholder="Promo Code"
                                padding="11.5px 14px"
                                height="44px"
                                value={promoCode}
                                onChange={(e) => {
                                    setPromoCode(e.target.value);
                                }}
                                onFocus={() => sendEvent({
                                    eventName: trackEvents.HIGH_IQ_PRMO_CODE_WRITE,
                                    // payload: {
                                    //     code: promoCodeRef?.current?.value
                                    // }
                                })}
                                icon={<Button
                                    text="Apply"
                                    type="ghost"
                                    color={promoCode !== "" ? "#4F48E2" : "#AAAAAA"}
                                    weight="semibold"
                                    disabled={promoCodeSubmitting}
                                    loading={promoCodeSubmitting}
                                    background="transparent"
                                />}
                                iconPlacement="right"
                                ref={promoCodeRef}
                                error={promoCodeError}
                                disabled={promoCodeSubmitting}
                                errorMargin="6px 0 0 0"
                                style={{ width: '100%', height: '44px' }}
                            />
                        }
                    </form>
                    <div className="bill__main">
                        <table>
                            <tr>
                                <td>
                                    <strong>Hi-IQ</strong> <span className="period">
                                        (billed {monthly ? "monthly" : "annually"})
                                    </span>
                                </td>
                                <td className="metric">
                                    <strong>£{monthly ? monthlyHighIqPrice : annuallyHighIqPrice}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Net amount</strong>
                                </td>
                                <td className="metric">
                                    <strong>£{monthly ? monthlyHighIqPrice : annuallyHighIqPrice}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Sales Tax</strong>
                                </td>
                                <td className="metric">
                                    <strong>£{monthly ? monthlySalesTax : annualSalesTax}</strong>
                                </td>
                            </tr>
                            {!!discountPercent && (
                                <tr>
                                    <td>
                                        <strong>Discount ({discountPercent}%)</strong>
                                    </td>
                                    <td className="metric discount">
                                        <strong>-£{Number((((monthly ? monthlyHighIqPrice : annuallyHighIqPrice) + (monthly ? monthlySalesTax : annualSalesTax)) * (originalDiscountPercent / 100)).toFixed(2))}</strong>
                                    </td>
                                </tr>
                            )}
                            <tr className="bill__result">
                                <td>
                                    <strong>Total</strong>
                                </td>
                                <td className="metric">
                                    <strong>£{calculateTotal()}</strong>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className="checkout-form__footer">
                <div className="terms">
                    <Checkbox id="terms-checkbox" value={tc} onChange={(e: CheckboxChangeEvent) => {
                        setTc(e.target.checked);
                        sendEvent({
                            eventName: trackEvents.HIGH_IQ_CHECKOUT_TC,
                            payload: {
                                checked: e.target.checked
                            }
                        })
                    }} />
                    <label htmlFor="terms-checkbox">
                        I've read <a href="https://piqsuite.com/terms-and-condition" rel="noreferrer" target="_blank" style={{ textDecoration: "underline", color: 'black' }}>T&Cs</a> and <a href="https://piqsuite.com/privacy-policy" rel="noreferrer" target="_blank" style={{ textDecoration: "underline", color: 'black' }}>Privacy Policy</a>
                    </label>
                </div>
                <div style={{ display: "flex", gap: 8 }}>
                    <BackButton disabled={paymentLoading} />

                    <Button
                        type="primary"
                        text="Pay Now"
                        width="97px"
                        height="38px"
                        weight="semibold"
                        loading={paymentLoading}
                        disabled={paymentLoading || !tc || !!paymentError}
                    />
                </div>
            </div>
        </form>
    )
}