import { useLocation, Link } from "react-router-dom";
import "./BottomAction.scss";
import React, { useMemo } from 'react';
import { getUrlParam } from "src/utils/utilFunction";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";

const BottomAction = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === "/login";
    const isForgotPasswordPage = location.pathname === "/forgot-password";

    const stepFromParams = useMemo(() => getUrlParam("step"), [location.search])

    const { sendEvent } = useTrackerContext();

    return (
        <div className="bottom-action">
            {isLoginPage ? (
                <>
                    Not yet Registered?{" "}
                    <Link to={
                        stepFromParams === "1" ? {
                            pathname: '/register',  // Target path
                            search: location.search, // Preserve current query parameters
                        } : {
                            pathname: "/register"
                        }}
                        className="link"
                        onClick={
                            () => {
                                sendEvent({
                                    eventName: trackEvents.TAKE_ME_TO_BTN,
                                    payload: {
                                        from: "login",
                                        to: "signup"
                                    }
                                })
                            }
                        }
                    >Take me to Sign Up</Link>
                </>
            ) : isForgotPasswordPage ?
                <>
                    Remembered your Password?{" "}
                    <Link to={{
                        pathname: '/login',  // Target path
                        search: location.search, // Preserve current query parameters
                    }}
                        className="link"
                        onClick={
                            () => {
                                sendEvent({
                                    eventName: trackEvents.TAKE_ME_TO_BTN,
                                    payload: {
                                        from: "password-reset",
                                        to: "login"
                                    }
                                })
                            }
                        }
                    >Take me to Sign In</Link>
                </> : (
                    <>
                        Already Registered?{" "}
                        <Link to={{
                            pathname: '/login',  // Target path
                            search: (() => {
                                const params = new URLSearchParams(location.search);
                                params.delete('email');
                                params.delete('internalStep');
                                return params.toString();
                            })(), // Remove email and internalstep parameters
                        }} className="link" onClick={
                            () => {
                                sendEvent({
                                    eventName: trackEvents.TAKE_ME_TO_BTN,
                                    payload: {
                                        from: "signup",
                                        to: "login"
                                    }
                                })
                            }
                        }>Take me to Sign In</Link>
                    </>
                )}
        </div>
    );
};

export default BottomAction;
