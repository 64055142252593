import { SocketNames } from "src/interface/PiqState";
import { getWebFeedComponent } from "./Component";
import { DEFAULT_LAOUT_CONFIG } from "./DefaultLayout";
import { polymarketWidgetSockets } from "src/constants/polymarketWidget";

export type componentType =
  | "NEWS_FEED_COMPONENT"
  | "TWITTER_FEED_COMPONENT"
  | "VIDEO_FEED_COMPONENT"
  | "CHART_FEED_COMPONENT"
  | "APP_FEED_COMPONENT"
  | "PREMIUM_FEED_COMPONENT";
export type feedType = "NEWS_FEED" | "VIDEO_FEED" | "CHART_FEED";

export type appSelectorTypes =
  | "PARTNER_FEED"
  | "PIQ_FEED"
  | "CHART_FEED"
  | "TWITTER_FEED"
  | "VIDEO_FEED";
export interface Column {
  title: string;
  icon?: any;
  socket: SocketNames;
  reducer?: string;
  twitterAccount?: string;
  feedType?: feedType;
  componentType?: componentType;
  showToTabs: boolean;
  active: boolean;
  appSelectorTypes: appSelectorTypes;
}

export const defaultColumnLayoutConfigs: any[] = [
  {
    i: "fastreveal",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "newsbtc",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },

  {
    i: "stock-traders-almanac",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "bitcoinist",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "hilltowerresourceadvisors",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },

  {
    i: "cme",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "forexanalytix",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "mi2partners",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "mtsinsights",
    minW: 1,
    maxW: 2,
    w: 2,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "audiomercados",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "econostream",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "forexLive",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 7,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "emergingMarketWatch",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isDraggable: true,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "harkster",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 8,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "capital",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "piqfeeds",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 10,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "economic-data-and-earning-calendar",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "reuters",
    minW: 1,
    maxW: 2,
    w: 2,
    minH: 3,
    h: 8,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "market-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "geopolitics-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "uspolitics-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "ukpolitics-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "weather-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "crypto-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "squawks-tweets",
    minW: 1,
    maxW: 2,
    w: 2,
    minH: 3,
    h: 8,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "tradingview",
    minW: 2,
    minH: 3,
    w: 3,
    h: 4,
    maxW: 6,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "marketoverview",
    minW: 2,
    minH: 3,
    w: 2,
    h: 4,
    maxW: 6,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "forexcrossrates",
    minW: 2,
    minH: 3,
    w: 3,
    h: 4,
    maxW: 6,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "stockscreener",
    minW: 2,
    minH: 3,
    w: 3,
    h: 4,
    maxW: 6,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },

  {
    i: "cryptocomparecryptonews",
    minW: 2,
    minH: 4,
    w: 2,
    h: 4,
    maxW: 3,
    maxH: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "cryptocompareadvancedchart",
    minW: 3,
    minH: 5,
    w: 3,
    h: 8,
    maxW: 6,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "cryptocompareprices",
    minW: 2,
    minH: 2,
    w: 3,
    h: 4,
    maxW: 6,
    maxH: 4,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "investing-economic-calender",
    minW: 3,
    minH: 4,
    w: 3,
    h: 4,
    maxW: 3,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["se", "s"],
  },
  {
    i: "complete-intel",
    minW: 2,
    minH: 3,
    w: 2,
    h: 6,
    maxW: 2,
    maxH: 10,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      // "se",
      "s",
    ],
  },
  {
    i: "telegram",
    minW: 2,
    minH: 5,
    w: 2,
    h: 7,
    maxW: 5,
    maxH: 25,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["se", "s"],
  },
  {
    i: "piqPoliticsChina",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "sports",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqPoliticsUs",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "politicsUK",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqPoliticsEurope",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "cryptoTimes",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "creditNews",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "newsquawk-delay",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 7,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["s", "se"],
    isBounded: true,
  },
  {
    i: "newsquawk",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["s", "se"],
    isBounded: true,
  },
  {
    i: "hammerstone",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["s", "se"],
    isBounded: true,
  },
  {
    i: "tickerApp",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "sinocism",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqCommoditiesMarkets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "market-energy",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqFXMarkets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "stocks-data",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "all-news",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 11,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "customFeed1",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "customFeed2",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "customFeed3",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "customFeed4",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "customFeed5",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "brexit",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "politics123",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqAllMarket",
    minW: 1,
    maxW: 2,
    w: 2,
    minH: 3,
    h: 4,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqCrypto",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqCentralBank",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "NEWS_FEED_DEFAULT",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "APP_FEED_COMPONENT",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "bloomberg",
    h: 4,
    w: 3,
    minH: 3,
    minW: 2,
    maxW: 7,
    maxH: 9,

    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "schwab-network",
    h: 4,
    w: 3,
    minH: 3,
    minW: 2,
    maxW: 7,
    maxH: 9,

    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "VIDEO_FEED_DEFAULT",
    h: 4,
    w: 3,
    minH: 3,
    minW: 2,
    maxW: 7,
    maxH: 9,

    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "piqpodcast",
    h: 2,
    w: 3,
    minH: 2,
    minW: 3,
    maxW: 5,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  },
  {
    i: "cmegroup-app",
    h: 8,
    w: 3,
    minH: 5,
    minW: 2,
    maxW: 4,
    maxH: 12,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "fmoccut75bps",
    h: 2,
    w: 2,
    minH: 2,
    minW: 2,
    maxW: 2,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: false,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  },
  {
    i: "fmoccut50bps",
    h: 2,
    w: 2,
    minH: 2,
    minW: 2,
    maxW: 2,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: false,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  },
  {
    i: "fmoccut25bps",
    h: 2,
    w: 2,
    minH: 2,
    minW: 2,
    maxW: 2,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: false,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  },
  {
    i: "fmocunchanged",
    h: 2,
    w: 2,
    minH: 2,
    minW: 2,
    maxW: 2,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: false,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  },
  {
    i: "fmochike25bps",
    h: 2,
    w: 2,
    minH: 2,
    minW: 2,
    maxW: 2,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: false,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  },
  {
    i: "economiccalendarwidget",
    minW: 2,
    minH: 2,
    w: 2,
    h: 5,
    maxW: 3,
    maxH: 7,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "marketdatawidget",
    minW: 2,
    minH: 2,
    w: 3,
    h: 6,
    maxW: 6,
    maxH: 13,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "stockheatmap",
    minW: 2,
    minH: 2,
    w: 5,
    h: 5,
    maxW: 8,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "tickertaperegular",
    minW: 1,
    minH: 2,
    w: 4,
    h: 2,
    maxW: 10,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "forexheatmap",
    minW: 2,
    minH: 2,
    w: 3,
    h: 4,
    maxW: 6,
    maxH: 4,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "news",
    minW: 2,
    minH: 2,
    w: 2,
    h: 4,
    maxW: 4,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "singleticker",
    minW: 1,
    minH: 2,
    w: 2,
    h: 2,
    maxW: 2,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "yeartodatestockheatmap",
    minW: 2,
    minH: 2,
    w: 3,
    h: 4,
    maxW: 8,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "tickertapecompact",
    minW: 1,
    minH: 2,
    w: 4,
    h: 2,
    maxW: 10,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e"
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "watchlist",
    minW: 3,
    minH: 3,
    w: 4,
    h: 5,
    maxW: 8,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },

  // calendars
  {
    i: "financialjuiceeconomiccalendar",
    minW: 2,
    minH: 2,
    w: 2,
    h: 4,
    maxW: 2,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "myfxbookcalendar",
    minW: 2,
    minH: 2,
    w: 2,
    h: 4,
    maxW: 5,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "financialjuiceheadlines",
    minW: 1,
    minH: 2,
    w: 1,
    h: 4,
    maxW: 4,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "financialjuicetickstrikesquawk",
    minW: 1,
    minH: 2,
    w: 1,
    h: 2,
    maxW: 1,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  }
];

export const generateCoordinates = (
  colString: string,
  type: string = "NEWS_FEED_COMPONENT"
) => {
  var colConfigs = getColumnConfigurations(colString, type)
  return {
    ...{
      minW: colConfigs?.minW,
      maxW: colConfigs?.maxW,
      minH: colConfigs?.minH,
      maxH: colConfigs?.maxH,
      moved: colConfigs?.moved,
      static: colConfigs?.static,
      isResizable: colConfigs?.isResizable,
      isDraggable: colConfigs?.isDraggable,
      resizeHandles: colConfigs?.resizeHandles,
      w: colConfigs?.w,
      h: colConfigs?.h,
      type: type,
    },
    x: 5,
    y: 6,
    i: colString,
    // ...colConfigs,
  };
};





const getColumnConfigurations = (colString: string, type: string) => {
  let colConfigs


  if (type === "NEWS_FEED_COMPONENT" || type === "TWITTER_FEED_COMPONENT") {
    colConfigs =
      defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
        ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
        : defaultColumnLayoutConfigs.find(
          (col) => col.i === "NEWS_FEED_DEFAULT"
        );

  } else if (type === "APP_FEED_COMPONENT") {
    colConfigs =
      defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
        ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
        : defaultColumnLayoutConfigs.find(
          (col) => col.i === "APP_FEED_COMPONENT"
        );
  } else if (type === "PODCAST_COMPONENT") {
    colConfigs =
      defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
        ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
        : defaultColumnLayoutConfigs.find(
          (col) => col.i === "piqpodcast"
        );
  } else if (type === "CUSTOM_FEED_COMPONENT") {
    colConfigs =
      defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
        ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
        : defaultColumnLayoutConfigs.find(
          (col) => col.i === "customFeed1"
        );
  } else if (type === "TUTORIAL_FEED_COMPONENT") {
    colConfigs = defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
      ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
      : defaultColumnLayoutConfigs.find(
        (col) => col.i === "cmegroup-app"
      );
  }
  else {
    colConfigs = defaultColumnLayoutConfigs.filter((col) => col.i === colString)
      ?.length
      ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
      : defaultColumnLayoutConfigs.find(
        (col) => col.i === "VIDEO_FEED_DEFAULT"
      );
  }

  return colConfigs
}





export const defaultColumnCoordinates: any[] = DEFAULT_LAOUT_CONFIG.map(
  (item: any) => {
    const column_config = getColumnConfigurations(item.i, item.type)
    return {
      ...{
        minW: column_config?.minW,
        maxW: column_config?.maxW,
        minH: column_config?.minH,
        maxH: column_config?.maxH,
        moved: column_config?.moved,
        static: column_config?.static,
        isResizable: column_config?.isResizable,
        isDraggable: column_config?.isDraggable,
        resizeHandles: column_config?.resizeHandles,
        w: item?.w,
        h: item?.h,
        type: item.type,
      },
      x: item.x,
      y: item.y,
      i: item.i,
      // ...column_config,
    };
  }
);

export const customFeedsSockets = ['customFeed1', 'customFeed2', 'customFeed3', 'customFeed4', 'customFeed5']
export const customFeedsTitles = ['Custom Feed 1', 'Custom Feed 2', 'Custom Feed 3', 'Custom Feed 4', 'Custom Feed 5']

export const getColumnComponents = (
  colString: SocketNames,
  Columns: any,
  customeFeeds: any
) => {
  let column;
  if (customFeedsSockets.includes(colString)) {
    column = customeFeeds?.find((item: any) => item?.slug === colString)
  } else {
    column = Columns?.find((item: any) => item?.slug === colString)
  }
  if (!Columns?.length) return
  var webFeedComponent = getWebFeedComponent(colString, column);

  // if (!webFeedComponent) {
  //   webFeedComponent = {
  //     socket: colString,
  //     component: (
  //       <VideoPlayer
  //         socket={colString}
  //         props={props}
  //       />
  //     ),
  //   };
  // }
  const { component }: any = webFeedComponent;
  return component;
};