import { Skeleton } from 'antd'
import React from 'react'

const ColumnLoading = () => {
    return (
        <div className="v2-suite-column-skeleton " style={{ marginLeft: 4 }}>
            <Skeleton active loading paragraph title />
            <Skeleton active loading paragraph title />
            <Skeleton active loading paragraph title />
        </div>
    )
}

export default ColumnLoading;