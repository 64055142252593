import * as actionTypes from "./actionType";
import { updateObject } from "../utility";
import { defaultColumnCoordinates } from "src/SuiteConfig/columnConfiguration";
export const initialState = {
  error: null,
  loading: false,
  userDetail: null,
  darkMode: true,
  sideBarThemeColor: { type: "light", value: "#fff" },
  suiteLayout: defaultColumnCoordinates,
  articleListConfig: null,
};

const editArticleListConfig = (state: any, action: any) => {
  return updateObject(state, {
    articleListConfig: action.payload,
  });
};

const userEditStart = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const userEditSuccess = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: false,
    userDetail: action.userDetail,
  });
};
const userEditFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const userDetailStart = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const userDetailSuccess = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: false,
    userDetail: action.userDetail,
    user: null,
  });
};
const userDetailFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const userDeleteStart = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const userDeleteSuccess = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: false,
    userDetail: action.userDetail,
  });
};
const userDeleteFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const userToggleDarkMode = (state: any, action: any) => {
  return updateObject(state, {
    darkMode: action.mode,
  });
};

const userLogOut = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: null,
    userDetail: null,
    darkMode: false,
    suiteLayout: defaultColumnCoordinates,
  });
};

const setUserDetails = (state: any, action: any) => {
  return updateObject(state, {
    userDetail: action.payload,
    loading: false,
  });
};
const setSidebarThemeColor = (state: any, action: any) => {
  return updateObject(state, {
    sideBarThemeColor: action.payload,
  });
};

const userEditLayout = (state: any, action: any) => {
  const updatedSuiteLayout = state.suiteLayout.map((item: any) => {
    if (item.i === action.payload) {
      return { ...item, completeIntegilence: true };
    }
    return item;
  });

  return updateObject(state, {
    suiteLayout: updatedSuiteLayout,
  });
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.VERSION_CHANGE:
      return updateObject(state, {
        suiteLayout: initialState.suiteLayout,
      });
    case actionTypes.USER_EDIT_START:
      return userEditStart(state, action);
    case actionTypes.USER_EDIT_SUCCESS:
      return userEditSuccess(state, action);
    case actionTypes.USER_EDIT_FAIL:
      return userEditFail(state, action);

    case actionTypes.USER_DETAIL_START:
      return userDetailStart(state, action);
    case actionTypes.USER_DETAIL_SUCCESS:
      return userDetailSuccess(state, action);
    case actionTypes.USER_DETAIL_FAIL:
      return userDetailFail(state, action);

    case actionTypes.USER_DELETE_START:
      return userDeleteStart(state, action);
    case actionTypes.USER_DELETE_SUCCESS:
      return userDeleteSuccess(state, action);
    case actionTypes.USER_DELETE_FAIL:
      return userDeleteFail(state, action);

    case actionTypes.USER_TOGGLE_DARK_MODE:
      return userToggleDarkMode(state, action);

    case actionTypes.USER_LOG_OUT:
      return userLogOut(state, action);

    case actionTypes.USER_EDIT_LAYOUT:
      return userEditLayout(state, action);
    case actionTypes.EDIT_ARTICLE_LIST_CONFIG:
      return editArticleListConfig(state, action);

    case actionTypes.SET_USER_DETAILS:
      return setUserDetails(state, action);

    case actionTypes.SET_SIDEBAR_THEME_COLOR:
      return setSidebarThemeColor(state, action);

    default:
      return state;
  }
};
export default reducer;
