import React, { useState } from 'react';
import { SocketNames } from 'src/interface/PiqState';
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import useFeeds from 'src/Hooks/useFeeds';
import CryptoCompareAdvancedChartWidget from './CryptoCompareAdvancedChartWidget';

const CryptoCompareAdvancedChart: React.FC<{
    socket: SocketNames;
}> = ({ socket }) => {
    const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const { getColumnDetail } = useFeeds();
    const column = getColumnDetail(socket);

    const refresh_function = () => {
        setFetching(true);
        setReload(true);
    };

    return (
        <div className="view-chart-div">
            <ColumnHeader
                title={column?.title || ""}
                socket={socket}
                isFetching={isFetching}
                fetchFunction={() => refresh_function()}
                handleEditPanel={() => setShowEditPanel(true)}
            />
            {showEditPanel && socket ? (
                <ColumnHeaderOption
                    setShowEditPanel={setShowEditPanel}
                    socket={socket}
                />
            ) : (
                <CryptoCompareAdvancedChartWidget
                    reload={reload}
                    setFetching={setFetching}
                    setReload={setReload}
                />
            )}
        </div>
    )
}

export default CryptoCompareAdvancedChart