import React, { useState } from "react"
import TermsDialog from "../../Drawers/TermsDialog"
import PrivacyDialog from "../../PrivacyDialog"
import DMCADialog from "src/development/Container/Web/Components/DMCAPolicy"
import { getUrlParam } from "src/utils/utilFunction";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";


export function TermsConditionPrivacyPolicy() {
    const [showTermsDialog, setShowTermsDialog] = useState(false);
    const [showPrivacyPolicyDialog, setShowPrivacyPolicyDialog] = useState(false);
    const [showDMCADialog, setShowDMCADialog] = useState(false);
    const step = getUrlParam('step')
    console.log({ step })

    const { sendEvent } = useTrackerContext();

    if (step !== '2') return null

    return (
        <>
            <div className="privacy-policy">
                <a href="https://piqsuite.com/terms-and-condition" target="_blank" rel="noreferrer" onClick={() => {
                    sendEvent({
                        eventName: trackEvents.TERMS_AND_CONDITION_LINK,
                        payload: {
                            page: "login"
                        }
                    })
                }}>T&C's</a>
                <span>|</span>
                <a href="https://piqsuite.com/privacy-policy" target="_blank" rel="noreferrer" onClick={() => {
                    sendEvent({
                        eventName: trackEvents.PRIVACY_POLICY_LINK,
                        payload: {
                            page: "login"
                        }
                    })
                }}>Privacy Policy</a>
                <span>|</span>
                <a href="https://piqsuite.com/media/piq-dmca-policy-19-02-24.pdf" target="_blank" rel="noreferrer" onClick={() => {
                    sendEvent({
                        eventName: trackEvents.DMCA_LINK,
                        payload: {
                            page: "login"
                        }
                    })
                }}>DMCA</a>
            </div>
            <TermsDialog
                openTerms={showTermsDialog}
                setOpenTerms={() => setShowTermsDialog(false)}
            />
            <PrivacyDialog
                openPrivacyPolicy={showPrivacyPolicyDialog}
                setOpenPrivacyPolicy={() => setShowPrivacyPolicyDialog(false)}
            />
            <DMCADialog
                openDMCAPolicy={showDMCADialog}
                setOpenDMCAPolicy={() => setShowDMCADialog(false)}
            />
        </>
    )
}