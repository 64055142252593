import React, { useState } from "react";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import useFeeds from "src/Hooks/useFeeds";
import { SocketNames } from "src/interface/PiqState";
import MyFxBookCalendarWidget from "./MyFxBookCalendarWidget";

type Props = {
    socket: SocketNames;
};

const MyFxBookCalendar = ({
    socket
}: Props) => {

    const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const { getColumnDetail } = useFeeds();
    const column = getColumnDetail(socket);

    const refresh_function = () => {
        setFetching(true);
        setReload(true);
    };

    const propsToPass = {
        setReload,
        setFetching,
        reload
    }

    return <div className="view-chart-div generic">
        <ColumnHeader
            title={column?.title || ""}
            socket={socket}
            isFetching={isFetching}
            fetchFunction={() => refresh_function()}
            handleEditPanel={() => setShowEditPanel(true)}
        />
        {showEditPanel && socket ? (
            <ColumnHeaderOption
                setShowEditPanel={setShowEditPanel}
                socket={socket}
            />
        ) : (
            <div className="widget-container" style={{
                height: "100%"
            }}>
                <MyFxBookCalendarWidget
                    setFetching={setFetching}
                    setReload={setReload}
                    reload={reload}
                />
            </div>
        )}
    </div>;
};

export default MyFxBookCalendar;
