import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { premiumDataDetails } from "src/development/Component/AppSelector/premiumData";
import { axiosInstance } from "../utility";
import _ from "lodash";
import { check_if_token_is_expired } from "../userStore";


const initialState = {
  data: premiumDataDetails,
  subscribedData: [],
  subscribedDataToShow: [],
  hasNewsQuawkSubscription: false,
  hasHammerstoneSubscription: false,
};

export const fetchPremiumAppData: any = createAsyncThunk(
  "premiumapp/fetchpremiumappData",
  async (arg, thunkApi) => {
    try {
      const res = await axiosInstance.get(`premiumapp/getAllPremiumApps`);
      return thunkApi.fulfillWithValue(res.data.data);
    } catch (err: any) {
      thunkApi.dispatch(check_if_token_is_expired(err.response));
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const premiumAppsSlice = createSlice({
  name: "premiumapps",
  initialState,
  reducers: {
    updatePremiumAppData: (state: any, action: any) => {
      state.data = action.payload;
    },
    resetSubscribedData: (state: any, action: any) => {
      state.subscribedData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPremiumAppData.pending, (state) => {
        // state.data = premiumDataDetails
      })
      .addCase(fetchPremiumAppData.fulfilled, (state, action) => {

        const subscribedPremiumApps =
          action?.payload?.length === 0
            ? []
            : _?.flatten(
                action?.payload?.map((data: any) =>
                  data?.premiumApps
                )
              );


        let filteredData = premiumDataDetails?.filter(
          (item) => subscribedPremiumApps.includes(item.title) === false
        );
        let filteredDataToShow: any = premiumDataDetails?.filter(
          (item) => subscribedPremiumApps.includes(item.title) === true
        );

        // for newsquawk
        const newsQuawkSubscription =
          action?.payload?.find(
            (item: any) =>
              item?.premiumApps?.includes("Newsquawk") && item.active
          ) || {};
        state.hasNewsQuawkSubscription =
          Object.keys(newsQuawkSubscription)?.length > 0 ? true : false;
        // for hammerstone
        const hammerstoneSubscription =
          action?.payload?.find(
            (item: any) =>
              item?.premiumApps?.includes("Hammerstone") && item.active
          ) || {};
        state.hasHammerstoneSubscription =
          Object.keys(hammerstoneSubscription)?.length > 0 ? true : false;
        state.data = filteredData;
        state.subscribedDataToShow = filteredDataToShow;
        state.subscribedData = action.payload;
      })
      .addCase(fetchPremiumAppData.rejected, (state, action) => {
        state.data = premiumDataDetails;
        state.subscribedDataToShow = [];
        state.subscribedData = [];
        state.hasNewsQuawkSubscription = false;
      });
  },
});

export const { updatePremiumAppData, resetSubscribedData } =
  premiumAppsSlice.actions;
export default premiumAppsSlice.reducer;