import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import completeIntelligence from "src/style/images/3rd.png";
import { axiosInstance } from "src/store/utility";
import useColumnHeader from "src/Hooks/useColumnHeader";
import { check_if_token_is_expired } from "src/store/userStore";

const API_URL = process.env.REACT_APP_API_URL;

const ThirdPartyApp = ({ socket, setLayoutData, piqSendEmail, setPiqSendEmail }: any) => {
  const { removeColumn } = useColumnHeader();

  const [selectedCol, setSelectedCol] = useState("");
  const [piqAppPermission, setPiqAppPermission] = useState(false);
  const [piqTermsCondition, setPiqTermsCondition] = useState(false);
  const [loading, setLoading] = useState(false);
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const dispatch = useDispatch()
  /**
   * Handles saving the column with the given name.
   *
   * @param {string} col - The name of the column to save.
   * @return {void} This function does not return anything.
   */
  const handleSaveColumn = async (col: string) => {
    // userEditLayout(socket)
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        `${API_URL}/completeintelligence/create`,
        {
          piqAppPermission,
          piqTermsCondition,
          piqSendEmail,
        }
      )
      if (res.data.status) {
        setLayoutData((prev: any) => ({ ...prev, completeIntegilence: true }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      dispatch(check_if_token_is_expired(error?.response))
      setLoading(false);
    }

    // dispatch({
    //   type: "USER_EDIT_LAYOUT",
    //   payload: socket,
    // });
  };

  return (
    <>
      <div>
        <img
          alt="PiQSuite.com"
          src={completeIntelligence}
          loading="lazy"
          style={{ cursor: "pointer", width: "40%", height: "40%" }}
        />
      </div>
      <div style={{ marginLeft: "20px" }}>
        <h4
          style={{
            marginTop: "20px",
            lineHeight: "0.5",
            color: darkMode ? "white" : "initial",
          }}
        >
          You are adding a 3rd Party App to your dashboard
        </h4>
        <p
          style={{ marginBottom: "5px", color: darkMode ? "white" : "initial" }}
        >
          3rd Party Apps are apps made available to you on the PiQ Suite
          dashboard but are are published and hosted by 3rd party providers. You
          will be subject to this publisher’s own Terms of Use and Privacy
          Policies to use this app.
        </p>

        <div style={{ marginTop: "25px", width: "80%" }}>
          <div style={{ display: "flex", gap: "10px", marginTop: "8px" }}>
            <Checkbox
              checked={piqAppPermission}
              onClick={() => setPiqAppPermission(!piqAppPermission)}
              name="piqAppPermission"
            />
            <span
              className="terms-checkbox-text"
              style={{ color: darkMode ? "white" : "initial" }}
            >
              PiQ can share my name and email address with this publisher for
              the sole purpose of creating and hosting an account with this app
            </span>
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "8px" }}>
            <Checkbox
              checked={piqTermsCondition}
              onClick={() => setPiqTermsCondition(!piqTermsCondition)}
              name="piqTermsCondition"
            />
            <span
              className="terms-checkbox-text"
              style={{ color: darkMode ? "white" : "initial" }}
            >
              I’ve read Complete Intelligence’s T&C’s & Privacy Policy
            </span>
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "8px" }}>
            <Checkbox
              checked={piqSendEmail}
              onClick={() => setPiqSendEmail(!piqSendEmail)}
              name="piqSendEmail"
            />
            <span
              className="terms-checkbox-text"
              style={{ color: darkMode ? "white" : "initial" }}
            >
              Complete Intelligence can send me marketing emails
            </span>
          </div>
        </div>
      </div>
      <br />
      <br />
      {/* <br />
                <br /> */}

      <div
        style={{
          display: "flex",
          justifyContent: "right",
          color: darkMode ? "white" : "initial",
        }}
      >
        <button
          key="back"
          className="cancel-btn"
          onClick={() => {
            setSelectedCol("");
            removeColumn(socket);
          }}
          style={{
            marginRight: "10px",
            backgroundColor: darkMode ? "white" : "initial",
          }}
        >
          Don't Activate
        </button>
        <button
          className={`standard-btn ${!piqAppPermission || !piqTermsCondition || loading ? "disabled" : ""
            }`}
          onClick={() => handleSaveColumn(selectedCol)}
          key="submit"
          style={{
            backgroundColor: darkMode ? "white" : "initial",
          }}
          disabled={!piqAppPermission || !piqTermsCondition || loading}
        >
          Activate App
        </button>
      </div>
    </>
  );
};

export default ThirdPartyApp;
