import { compose } from "redux";
import thunk from "redux-thunk";

import UserReducer from "./User/reducer";
import { PIQReducer } from "./PIQ/reducer";
import piqvideofeedReducer from "./piqtv/reducer";
import PiQAdsReducer from "./piqads/reducer";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import * as serviceWorker from "../serviceWorker";

import { persistReducer, persistStore } from "redux-persist";
import subscriptionSlice from "./Subscriptions/subscriptionSlice";
import piqcolumnsSlice from "./PiQColumns/index";
import loadingSlice from "./Loading/index";
import { Auth } from "aws-amplify";
import premiumAppsSlice from "./PremiumApps/premiumAppsSlice";
import limitExceedSlice from "./LimitExceed/limitExceedSlice";
import inFeedAdsSlice from "./InFeedAds/index";
import podcastSlice from "./podcasts/index";
import partnerChannelsSlice from "./partnerChannel";

import userSlice from "./userStore/index";
import feedSharingSlice from "./FeedSharing/index";
import piqNewsFeedSlice from "./PiQColumns/piqNewsFeed";
import rssFeedSlice from "./PiQColumns/rssFeed";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const reducerList = [
  "user",
  // "PIQReducer",
  "videoFeed",
  "piqads",
  "piqcolumns",
  "podcasts",
  "partnerFeeds",
  "piqNewsFeed",
  "rssFeed",
];
export const persistConfig = {
  key: "root",
  storage,
  whitelist: reducerList,
};

const appReducer = combineReducers({
  /* your app's top-level reducers */
  user: UserReducer,
  videoFeed: piqvideofeedReducer,
  PIQReducer: PIQReducer,
  piqads: PiQAdsReducer,
  subscription: subscriptionSlice,
  piqcolumns: piqcolumnsSlice,
  premiumapps: premiumAppsSlice,
  loading: loadingSlice,
  limit: limitExceedSlice,
  infeedads: inFeedAdsSlice,
  podcasts: podcastSlice,
  partnerChannels: partnerChannelsSlice,
  user_data: userSlice,
  feedSharing: feedSharingSlice,
  piqNewsFeed: piqNewsFeedSlice,
  rssFeed: rssFeedSlice,
});
const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOG_OUT") {
    Auth.signOut();
    // reducerList.forEach((item: string) => {
    //   if (state !== undefined) {
    //     switch (item) {
    //       case "user":
    //         state[item] = userInitalState;
    //     }
    //   }
    // });
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  devTools: serviceWorker.isLocalhost,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
