import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import reload_img_dark from "src/style/images/Reload_Icon_dark.svg";
import reload_img_light from "src/style/images/Reload_Icon_light.svg";
export const RefreshButtoneComponent: React.FC<{
  fetchFunction: () => any;
  isFetching?: boolean;
}> = ({ fetchFunction, isFetching }) => {
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  return (
    <span
      className={`v2-column-header-reload-icon`}
      onClick={() => fetchFunction()}
    >
      {isFetching && (
        <LoadingOutlined
          style={{
            fontSize: "13px",
            color: darkMode ? "#8989a1" : "black",
            strokeWidth: "50", // --> higher value === more thickness the filled area
            stroke: darkMode ? "#8989a1" : "black",
          }}
        />
      )}
      {!isFetching &&
        //  <ReloadOutlined

        //     style={{
        //         fontSize: "13px",
        //         color: darkMode ? "#8989a1" : "black",
        //         strokeWidth: "50", // --> higher value === more thickness the filled area
        //         stroke: darkMode ? "#8989a1" : "black",

        //     }}
        // />
        (!darkMode ? (
          <img src={reload_img_dark} alt="" />
        ) : (
          <img style={{ filter: "none" }} src={reload_img_light} alt="" />
        ))}
    </span>
  );
};
