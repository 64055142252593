import moment from "moment";
import { SocketNames } from "src/interface/PiqState";
import { loadFeedData } from "src/store/PIQ/action";
import {
  deleteCustomFeedConfig,
  postCustomFeedConfig,
  postSuiteConfig,
} from "src/store/User/action";
import { remove_auth_headers } from "src/store/utility";
// import { replaceItemInArray } from "./utilFunction";

export const diff_minutes = async (dt2: Date, dt1: Date) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};
export const splitArrayIntoChunk = async (arr: any, chunk: number) => {
  var totalArray = [];
  for (let i = 0; i < arr.length; i += chunk) {
    let tempArray;
    tempArray = arr.slice(i, i + chunk);
    totalArray.push(tempArray);
  }
  return totalArray;
};

export const not_null_and_undefined = (data: any) => {
  return data != null && data !== undefined;
};

export const convert_timestamp_into_date = (date: number) => {
  var dateFormat = new Date(date);
  return dateFormat.getDay;
};

export const remove_item_from_array = (array: any, item: any) => {
  const index = array.indexOf(item);
  if (index > -1) {
    // only splice array when item is found
    array.splice(index, 1); // 2nd parameter means remove one item only
  }
  return array;
};

export const executeScroll = (dataCol: any) => {
  if (dataCol) {
    dataCol.current.scrollTop = 0;
  }
};
export const sortByTitle = (data: any) => {
  if (data) {
    return [...data].sort((a: any, b: any) => a.title.localeCompare(b.title));
  }
  return data;
};
export const handleColumnScroll = (
  fetchNow: boolean = false, //to forcefully fetch the data without reaching the scrollHheight
  dataColRef: any,
  setScrollTop: any = null,
  page: number,
  setPage: any = null,
  fetchData: any = null,
  setIsFetching: any = null,
  isFetching: boolean
) => {
  const { scrollTop, scrollHeight, clientHeight } = dataColRef.current;
  if (typeof setScrollTop == "function") {
    if ((dataColRef.current && scrollTop >= 90) || fetchNow) {
      setScrollTop(true);
      if (
        (scrollTop >= scrollHeight - clientHeight - 10 || fetchNow) &&
        !isFetching
      ) {
        setIsFetching(true);
        // dataColRef.current.scrollTop = scrollTop - 200;
        setPage(page + 1);
        if (typeof fetchData == "function") {
          fetchData(page + 1);
        }
      }
    } else {
      setScrollTop(false);
    }
  }
};

export const RemoveSuiteLayoutItem = async (
  layouts: any,
  is_authenticated: boolean,
  email: string,
  darkMode: boolean
) => {
  const newLayout = layouts?.map((item: any) => {
    return {
      i: item?.i,
      x: item?.x,
      y: item?.y,
      w: item?.w,
      h: item?.h,
      type: item?.type,
    };
  });

  if (is_authenticated) {
    await postSuiteConfig({
      layout: newLayout,
      darkMode: darkMode,
      email: email,
    });
  }
};
export const RemoveSuiteLayoutCustomItem = async (
  socket: string,
  is_authenticated: boolean
) => {
  if (is_authenticated) {
    await deleteCustomFeedConfig(socket);
  }
};

export const updateLocalStorageSuiteLayout = async (
  layouts: any,
  is_authenticated: boolean,
  email: string,
  darkMode: boolean
) => {
  const newLayout = layouts?.map((item: any) => {
    return {
      i: item?.i,
      x: item?.x,
      y: item?.y,
      w: item?.w,
      h: item?.h,
      type: item?.type,
    };
  });

  if (is_authenticated && newLayout?.length) {
    await postSuiteConfig({
      layout: newLayout,
      darkMode: darkMode,
      email: email,
    });
  }
};
export const updateCustomFeedLayout = async (
  layouts: any,
  is_authenticated: boolean,
  slug: string,
  nearestMissingTitle: string
) => {
  const newLayout = {
    i: layouts?.i,
    x: layouts?.x,
    y: layouts?.y,
    w: layouts?.w,
    h: layouts?.h,
    type: layouts?.type,
    slug,
    title: nearestMissingTitle,
    previousTitle: nearestMissingTitle,
  };

  if (is_authenticated && Object.keys(newLayout)?.length) {
    await postCustomFeedConfig(newLayout);
  }
};

export const getSuiteConfigFromLocalStorage = (email: string) => {
  if (localStorage.getItem("piq-suite-config")) {
    const piqSuiteConfig = JSON.parse(
      localStorage.getItem("piq-suite-config") as string
    );
    return piqSuiteConfig?.AUTH?.find((item: any) => item?.EMAIL === email);
  }
};

export const saveChangedDarkMode = async (
  isDarkMode: boolean,
  user: any,
  customColumnLayoutConfigs: any
) => {
  if (user) {
    await postSuiteConfig({
      layout: customColumnLayoutConfigs,
      darkMode: isDarkMode,
      email: user?.attributes?.email,
    });
  }
};

export const suiteAuthAndUnAuthConfigAreEqual = () => {
  if (localStorage.getItem("piq-suite-config")) {
    const piqSuiteConfig = JSON.parse(
      localStorage.getItem("piq-suite-config") as string
    );
    return (
      JSON.stringify(piqSuiteConfig?.["AUTH"]) ===
      JSON.stringify(piqSuiteConfig?.["UNAUTH"])
    );
  } else {
    return false;
  }
};

export const clearLocalStoreage = () => {
  remove_auth_headers();
};

export const openNewWindow = (url: string) => {
  const name = "PiQSuite.com";
  const width = 550;
  const height = 480;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;

  const options = `
    toolbar=no,
    location=no,
    directories=no,
    status=no,
    menubar=no,
    scrollbars=no,
    resizable=no,
    copyhistory=no,
    width=${width},
    height=${height},
    top=${top},
    left=${left}
  `;

  window.open(url, name, options);
};

export const openNewTab = (url: string) => {
  window.open(url, "_blank");
};


export const scrollToBottom = (id: string) => {
  const element = document.querySelector(id);
  element?.scrollTo(0, element?.scrollHeight + 500);
};

export const getStripeUrl = async (is_monthly: boolean) => {
  //COMMENTED:Live Url
  const path = is_monthly
    ? "https://buy.stripe.com/28o3fOacefQB1CUcMM"
    : "https://buy.stripe.com/28o9Ecbgi1ZLgxOeUV";

  //COMMENTED:Test Url
  // const path = is_monthly
  //   ? "https://buy.stripe.com/test_4gw6oL93k0ihcTe6oo"
  //   : "https://buy.stripe.com/test_eVafZl7Zg5CB3iE289";

  return path;
};

export const pushAdsToBody = (deviceWidth: number) => {
  if (deviceWidth <= 812) {
    const divToRemove = document.getElementById("ic_d_div_OOP_1");
    if (divToRemove) {
      divToRemove.remove();
    }
    const existingDev = document.getElementById("test");
    if (!existingDev) {
      const template = document.createElement("div");
      template.style.cssText = "margin: auto; text-align: center";
      template.id = "test";
      document.body.appendChild(template);
      window.createNewPage();
    }
  } else {
    const divToRemove = document.getElementById("test");
    if (divToRemove) {
      divToRemove.remove();
    }
    const existingDev = document.getElementById("ic_d_div_OOP_1");
    if (!existingDev) {
      const template = document.createElement("div");
      template.style.cssText = "margin: auto; text-align: center";
      template.id = "ic_d_div_OOP_1";
      document.body.appendChild(template);
      window.createNewPage();
    }
  }
};

export const removeAdsToBody = () => {
  const divToRemove = document.getElementById("ic_d_div_OOP_1");
  if (divToRemove) {
    divToRemove.remove();
  }
  const ifrmeToRemove = document.getElementById("IC_PM_Uid_Iframe");
  if (ifrmeToRemove) {
    ifrmeToRemove.remove();
  }
  // Remove the script if the user now has the subscription
  // const scriptElements = document.querySelectorAll("script");
  // console.log(scriptElements, "scriptElements");
  // scriptElements.forEach((scriptElement) => {
  //   const src = scriptElement.getAttribute("src");
  //   console.log("scriptElements", src);

  //   if (src && src.includes("https://u5.investingchannel.com")) {
  //     scriptElement?.parentNode?.removeChild(scriptElement);
  //   }
  // });

  // const linkElements = document.querySelectorAll("link");
  // console.log(linkElements, "linkElements");
  // linkElements.forEach((linkElement) => {
  //   const href = linkElement.getAttribute("href");
  //   console.log("linkElements", href);

  //   if (href && href.includes("https://u5.investingchannel.com")) {
  //     linkElement?.parentNode?.removeChild(linkElement);
  //   }
  // });

  // const iframeElements = document.querySelectorAll("iframe");
  // console.log(iframeElements, "iframeElements");
  // iframeElements.forEach((iframeElement) => {
  //   const src = iframeElement.getAttribute("src");
  //   console.log("iframeElements", src);
  //   if (src && src.includes("https://u5.investingchannel.com")) {
  //     iframeElement?.parentNode?.removeChild(iframeElement);
  //   }
  // });
};

export const getYoutubeId = (url: string) => {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/;
  var match = url?.match(regExp);

  if (match && match[2]?.length === 11) {
    return match[2];
  } else {
    return "error";
  }
};
export const getReutersUniqueUrl = (id: string, socket: SocketNames) => {
  return `${
    window?.location?.hostname !== "localhost"
      ? "https://m.piqsuite.com"
      : window?.location?.origin || "https://piqsuite.com"
  }/${socket}/${id}`;
};

export const getUrlToShare = (item: any, socket: SocketNames) => {
  if (
    socket === "piqfeeds" ||
    socket === "economic-data-and-earning-calendar"
  ) {
    if (
      item?.type === "investingEconomicCalender" ||
      item?.type === "investingEarningCalender"
    ) {
      return window?.location?.origin || "https://piqsuite.com";
    } else {
      return getReutersUniqueUrl(item?.slug, item?.type);
    }
  }
  if (
    (socket === "econostream" ||
      socket === "audiomercados" ||
      socket === "emergingMarketWatch" ||
      socket === "hilltowerresourceadvisors" ||
      socket === "fastreveal" ||
      socket === "newsbtc" ||
      socket === "bitcoinist" ||
      socket === "forexLive" ||
      socket === "newsquawk" ||
      socket === "reuters" ||
      socket === "newsquawk-delay" ||
      socket === "hammerstone") &&
    (item?.slug || item?.uniqueSlug)
  ) {
    return getReutersUniqueUrl(item?.slug || item?.uniqueSlug, socket);
  } else {
    return item?.canonicalUrl;
  }
};

export const getFormatedDate = (data: any) => {
  return moment(data).format("MMM Do, HH:mm:ss");
};

export const getFormatedDataForArticleList = (data: any) => {
  const pubDate = moment.utc(data);
  const formattedDate = pubDate.format("hh:mm A [GMT]");

  const currentDate = moment.utc();
  const diffInMinutes = currentDate.diff(pubDate, "minutes");

  const updatedText = `Updated ${diffInMinutes} min ago`;

  return `${formattedDate} . ${updatedText}`;
};

const getDifference = (array1: any, array2: any) => {
  return array1.filter((object1: any) => {
    return !array2.some((object2: any) => {
      return Object.keys(object1).every((key: string) => {
        return object1[key] === object2[key];
      });
    });
  });
};

export const getTwoArrayDifference = (array1: any, array2: any) => {
  return [...getDifference(array1, array2), ...getDifference(array2, array1)];
};

export const loadDataFromSocket = (
  socket: SocketNames,
  reducer: string,
  data: any,
  dataColRef: any,
  displayScrollTop: any,
  list: any,
  PAGE_LIMIT: number,
  dispatch: any
) => {
  if (!dataColRef || !data) return;
  if (displayScrollTop) return;
  // var slicedData = [
  //   ...data?.data,
  //   ...list?.splice(0, list?.length - data?.data?.length),
  // ];
  var slicedData = [...data?.data, ...list];
  slicedData.length = PAGE_LIMIT;
  if (socket === "reuters" || socket === "reutersdelay") {
    slicedData = slicedData.reduce((first: any[], second: any) => {
      if (!first.find((item: any) => item.title === second.title)) {
        //uniqueSlug
        first.push(second);
      }
      return first;
    }, []);
  }
  // if (slicedData.length > PAGE_LIMIT) {
  //   slicedData.length = PAGE_LIMIT;
  // }
  slicedData = slicedData.sort(function (a: any, b: any) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    const dateA: any = new Date(a.pubDate);
    const dateB: any = new Date(b.pubDate);
    return dateB - dateA;
  });

  dispatch(
    loadFeedData(
      {
        ...data,
        data: slicedData,
      },
      reducer
    )
  );
};

export const switch_socket_in_response_to_auth = (
  customColumnLayoutConfigs: any[],
  isSignedIn: boolean
) => {
  if (customColumnLayoutConfigs?.length) {
    const updateSuiteLayout = customColumnLayoutConfigs?.map((item: any) => {
      if (isSignedIn && item.i === "reutersdelay") {
        return {
          ...item,
          i: "reuters",
        };
      } else if (!isSignedIn && item.i === "reuters") {
        return {
          ...item,
          i: "reutersdelay",
        };
      } else {
        return item;
      }
    });
    return updateSuiteLayout;
  } else {
    return customColumnLayoutConfigs;
  }
};

export const compareArrays = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1
    .slice()
    .sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));
  const sortedArr2 = arr2
    .slice()
    .sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));

  for (let i = 0; i < sortedArr1.length; i++) {
    if (JSON.stringify(sortedArr1[i]) !== JSON.stringify(sortedArr2[i])) {
      return false;
    }
  }

  return true;
};

export function uppercaseFirstLetter(str: any) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
