import React, { createContext, useEffect, useState } from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()

interface IDeviceInfoContext {
    deviceId: null | string;
    platform: null | string;
    timezone: null | string;

}

export const DeviceInfoContext = createContext<IDeviceInfoContext>({
    deviceId: null,
    platform: null,
    timezone: null

})

export const useDeviceInfoContext = () => React.useContext(DeviceInfoContext);

export const DeviceInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {


    const [deviceInfo, setDeviceInfo] = useState<IDeviceInfoContext>({
        deviceId: null,
        platform: null,
        timezone: null
    })


    useEffect(() => {

        async function loadInfo() {
            const fp = await fpPromise;
            const result: any = await fp.get();
            // console.log("Result - ", result, result.visitorId)
            setDeviceInfo((prev) => ({
                ...prev,
                deviceId: result?.visitorId,
                platform: navigator?.platform,
                timezone: result?.components?.timezone?.value
            }));
        }

        loadInfo()
    }, [])


    return (
        <DeviceInfoContext.Provider
            value={
                deviceInfo
            }
        >
            {children}
        </DeviceInfoContext.Provider>
    )
}