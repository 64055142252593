import React from "react";
import { Collapse, Radio, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import {
  getpiqvideofeed,
  piqvideofeedGetSuccess,
} from "src/store/piqtv/action";
import { useSelector } from "react-redux";
import { CaretRightOutlined } from "@ant-design/icons";
import config from "src/config";
import useColumnHeader from "../../../Hooks/useColumnHeader";
import { not_null_and_undefined } from "src/utils/common";
const { Panel } = Collapse;
const VideoList: React.FC<{
  closeVideoEditPanel: (params: any) => any;
  data: any[];
  socket: string;
}> = ({ closeVideoEditPanel, data, socket, }) => {
  const dispatch = useDispatch();
  const { piqTVs, darkMode } = useSelector((state: any) => ({
    piqTVs: state?.videoFeed?.piqvideofeed?.data,
    darkMode: state?.user?.darkMode,
  }));

  const onEditChange = (oldCol: string, newCol: string) => {
    handleUpdateSaveColumn({ oldCol, newCol });
  };

  const { handleUpdateSaveColumn, alreadySelectedDialog, setAlreadySelectedDialog } =
    useColumnHeader();

  return (
    <div
      className="v2-edit-panel"
      style={{ background: darkMode ? "#384251" : "#ffffff" }}
    >
      <Collapse
        accordion
        className="v2-edit-panel-collapse"
        bordered={false}
        onChange={(e) => {
          e
            ? dispatch(getpiqvideofeed({ category: e }) as any)
            : dispatch(piqvideofeedGetSuccess({}) as any);
        }}
        expandIconPosition={"end"}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {data?.length ? (
          data?.map((item: any, index: number) => (
            <Panel
              key={item?._id}
              header={item?.label}
              style={{ border: "none" }}
            >
              <Radio.Group size="small" className="v2-edit-panel-select">
                {/* <Space direction="vertical"> */}
                {not_null_and_undefined(piqTVs) && piqTVs?.map((col: any, index: number) => (
                  <Radio
                    value={index}
                    key={index}
                    onChange={() => onEditChange(socket, col?.slug)}
                  >
                    <img
                      src={config.PROD_URL + "/" + col.imageUrl}
                      alt=""
                      style={{
                        height: "10px",
                        width: "10px",
                        marginRight: "4px",
                      }}
                    />
                    <label className={" p-y-10"}>{col.title}</label>
                  </Radio>
                ))}
                {piqTVs?.length === 0 && <Radio>No Data Found</Radio>}

                {!not_null_and_undefined(piqTVs) && <Radio>
                  Loading...
                </Radio>}

                {/* </Space> */}
              </Radio.Group>
            </Panel>
          ))
        ) : (
          <Skeleton active />
        )}
      </Collapse>

      <div
        hidden={!alreadySelectedDialog}
        className="edit-save-dialog"
        style={{ background: darkMode ? "black" : "#ffffff" }}
      >
        <div className="red-text">
          You already have this feed selected. Please choose another.
        </div>
        <button
          className="ok-dialog-btn"
          style={{ marginRight: "10px" }}
          onClick={() => setAlreadySelectedDialog(false)}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default VideoList;
