import React from "react";
import { useSelector } from "react-redux";
import search_notfound_light from "src/style/images/search_notfound_light.svg";
import search_notfound_dark from "src/style/images/search_notfound_dark.svg";


function GlobalArticleNotFound({ searchedText }: any) {
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  return (
    <div
      className={
        darkMode ? "dark_without_search_container" : "without_search_container"
      }
    >
      <div className="image">
        {darkMode ? (
          <img
            className="icon"
            src={search_notfound_dark}
            alt="piq logo"
            width={80}
            height={80}
            style={{
              objectFit: "contain"
            }}
          />
        ) : (
          <img
            className="icon"
            src={search_notfound_light}
            alt="piq logo"
            width={80}
            height={80}
            style={{
              objectFit: "contain"
            }}
          />
        )}
      </div>
      <div className="para_first">Well, this is awkward!</div>
      <div className="para_second">
        We couldn’t find any results for ‘{searchedText}’  in the last 14 days. Please try another search keyword.
      </div>
    </div>
  );
}

export default GlobalArticleNotFound;
