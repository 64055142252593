import './PricingPaged.scss'
import React, { useState } from "react";
import PricingCard from './PricingCard';
import { pricingData } from 'src/constants/pricing';
import Toggle from '../../Toggle/Toggle';
import { addUrlParam } from 'src/utils/utilFunction';
import { trackEvents } from 'src/constants/tracker';
import { useTrackerContext } from 'src/context/TrackerProvider';


const PricingPage = () => {

    const [showMonthly, setShowMonthly] = useState<boolean>(false);
    const getRedirectLink = (item: any) => {

        if (item?.title !== "Hi-IQ") return item?.redirectLink;

        let searchParams = addUrlParam("subscriptionType", showMonthly ? "monthly" : "annually");
        return `${item?.redirectLink}?${searchParams}&with=checkout`
    }

    const { sendEvent } = useTrackerContext();

    return <div className="pricing-page">
        <h1>Our pricing plans</h1>
        <div className="pricing-toggle">
            <span>
                Monthly
            </span>
            <Toggle
                height="18px"
                width="32px"
                checked={!showMonthly}
                onToggle={(value: boolean) => {
                    sendEvent({
                        eventName: trackEvents.PRICING_PAGE_PRICE_TOGGLE,
                        payload: {
                            pricing: value ? "annually" : "monthly"
                        }
                    })
                    setShowMonthly(prev => !prev);
                }}
            />
            <span>
                Annually
            </span>
        </div>
        <div className="pricing-container">
            {
                pricingData?.map((item) => (
                    <PricingCard
                        key={item?.title}
                        title={item?.title}
                        annualPrice={item?.annualPrice}
                        monthlyPrice={item?.monthlyPrice}
                        description={item?.description}
                        services={item?.services}
                        monthly={showMonthly}
                        highlight={item?.highlight}
                        buttonText={item?.buttonText}
                        redirectLink={getRedirectLink(item)}
                        saving={item?.saving}
                    />
                ))
            }

        </div>
    </div>;
};

export default PricingPage;
