import React, { useEffect, useState } from "react";
import useInFeedAds from "src/Hooks/useInFeedAds";
import config from "src/config";
import { useTrackerContext } from "src/context/TrackerProvider";
import useSubscription from "src/Hooks/useSubscription";
import { useLocationContext } from "src/context/LocationProvider";
import { getRandomStaticInfeedAds } from "src/store/StaticData/ads";

const AdImage = ({ src, onClick }: { src: string; onClick: () => void }) => (
    <div
        className="v2-suite-column-card-item"
        style={{ cursor: 'pointer', padding: "0px !important" }}
        onClick={onClick}
    >
        <img
            src={src}
            alt="https://piqsuite.com"
            style={{ width: "-webkit-fill-available" }}
        />
    </div>
);

export const InFeedAds: React.FC<{ item: any; isWidth2: boolean }> = ({ item, isWidth2 }) => {
    const { handleAdsClick } = useInFeedAds();
    const { has_hiq_subscription } = useSubscription();
    const { isUS } = useLocationContext();
    const [randomAds, setRandomAds] = useState(getRandomStaticInfeedAds());
    useEffect(() => {
        setRandomAds(getRandomStaticInfeedAds());
    }, []);
    const itemImageSrc = config?.FEED_URL + item?.image_url;
    const sandConsoleClick = () => handleAdsClick({ title: "Sand Console", redirect_url: randomAds?.url });
    const itemClick = () => handleAdsClick(item);
    console.log(has_hiq_subscription, item?.visible_to_high_iq, "randomAds")
    const renderPepperstoneAd = () => {
        if (isWidth2) {
            return (
                <>
                    <div hidden={isUS}>
                        <AdImage src={itemImageSrc} onClick={itemClick} />
                    </div>
                    <div hidden={!isUS}>
                        <AdImage src={randomAds?.image} onClick={sandConsoleClick} />
                    </div>
                </>
            );
        }
        return <AdImage src={randomAds?.image} onClick={sandConsoleClick} />;
    };

    const renderRegularAd = () => (
        <AdImage src={itemImageSrc} onClick={itemClick} />
    );

    const renderDefaultAd = () => (
        <AdImage src={randomAds?.image} onClick={itemClick} />
    );

    if (has_hiq_subscription) {
        if (item?.visible_to_high_iq) {
            if (item?.type === "PEPPERSTONE") {
                return renderPepperstoneAd();
            } else {
                return renderRegularAd();
            }
        } else {
            return <></>;
        }
    } else {
        if (item?.type === "PEPPERSTONE") {
            return renderPepperstoneAd();
        } else {
            return renderRegularAd();
        }
    }




};