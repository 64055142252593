import { SocketNames } from "src/interface/PiqState";

export const marketOverviewWidgetConfig = (darkMode: boolean) =>
  JSON.stringify({
    colorTheme: darkMode ? "dark" : "light",
    dateRange: "12M",
    showChart: true,
    locale: "en",
    largeChartUrl: "",
    isTransparent: false,
    showSymbolLogo: true,
    showFloatingTooltip: false,
    plotLineColorGrowing: "rgba(41, 98, 255, 1)",
    plotLineColorFalling: "rgba(41, 98, 255, 1)",
    gridLineColor: "rgba(42, 46, 57, 0)",
    scaleFontColor: "rgba(209, 212, 220, 1)",
    belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
    belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
    belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
    belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
    symbolActiveColor: "rgba(41, 98, 255, 0.12)",
    tabs: [
      {
        title: "Indices",
        symbols: [
          {
            s: "FOREXCOM:SPXUSD",
            d: "S&P 500 Index",
          },
          {
            s: "FOREXCOM:NSXUSD",
            d: "US 100 Cash CFD",
          },
          {
            s: "FOREXCOM:DJI",
            d: "Dow Jones Industrial Average Index",
          },
          {
            s: "INDEX:NKY",
            d: "Nikkei 225",
          },
          {
            s: "INDEX:DEU40",
            d: "DAX Index",
          },
          {
            s: "FOREXCOM:UKXGBP",
            d: "FTSE 100 Index",
          },
        ],
        originalTitle: "Indices",
      },
      {
        title: "Futures",
        symbols: [
          {
            s: "CME_MINI:ES1!",
            d: "S&P 500",
          },
          {
            s: "CME:6E1!",
            d: "Euro",
          },
          {
            s: "COMEX:GC1!",
            d: "Gold",
          },
          {
            s: "NYMEX:CL1!",
            d: "WTI Crude Oil",
          },
          {
            s: "NYMEX:NG1!",
            d: "Gas",
          },
          {
            s: "CBOT:ZC1!",
            d: "Corn",
          },
        ],
        originalTitle: "Futures",
      },
      {
        title: "Bonds",
        symbols: [
          {
            s: "CBOT:ZB1!",
            d: "T-Bond",
          },
          {
            s: "CBOT:UB1!",
            d: "Ultra T-Bond",
          },
          {
            s: "EUREX:FGBL1!",
            d: "Euro Bund",
          },
          {
            s: "EUREX:FBTP1!",
            d: "Euro BTP",
          },
          {
            s: "EUREX:FGBM1!",
            d: "Euro BOBL",
          },
        ],
        originalTitle: "Bonds",
      },
      {
        title: "Forex",
        symbols: [
          {
            s: "FX:EURUSD",
            d: "EUR to USD",
          },
          {
            s: "FX:GBPUSD",
            d: "GBP to USD",
          },
          {
            s: "FX:USDJPY",
            d: "USD to JPY",
          },
          {
            s: "FX:USDCHF",
            d: "USD to CHF",
          },
          {
            s: "FX:AUDUSD",
            d: "AUD to USD",
          },
          {
            s: "FX:USDCAD",
            d: "USD to CAD",
          },
        ],
        originalTitle: "Forex",
      },
    ],
  });

export const stockScreenerWidgetConfig = (darkMode: boolean) =>
  JSON.stringify({
    width: "100%",
    height: "100%",
    defaultColumn: "overview",
    defaultScreen: "general",
    market: "america",
    showToolbar: true,
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
  });
export const cryptoWidgetConfig = (darkMode: boolean) =>
  JSON.stringify({
    width: "100%",
    height: "100%",
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
  });

export const forexCrossRatesWidgetConfig = (darkMode: boolean) =>
  JSON.stringify({
    width: "100%",
    height: "100%",
    currencies: ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD", "CNY"],
    isTransparent: false,
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
  });

export const economicCalendarWidgetConfig = (darkMode: boolean) =>
  JSON.stringify({
    colorTheme: darkMode ? "dark" : "light",
    isTransparent: false,
    width: "400",
    height: "550",
    locale: "en",
    importanceFilter: "-1,0,1",
    countryFilter:
      "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu",
  });

export const forexHeapMapConfig = (darkMode: boolean) =>
  JSON.stringify({
    width: "100%",
    height: "100%",
    currencies: ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD", "CNY"],
    isTransparent: false,
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
    backgroundColor: "#ffffff",
  });

export const marketDataWidgetConfig = (darkMode: boolean) =>
  JSON.stringify({
    width: "100%",
    height: "100%",
    symbolsGroups: [
      {
        name: "Indices",
        originalName: "Indices",
        symbols: [
          {
            name: "FOREXCOM:SPXUSD",
            displayName: "S&P 500 Index",
          },
          {
            name: "FOREXCOM:NSXUSD",
            displayName: "US 100 Cash CFD",
          },
          {
            name: "FOREXCOM:DJI",
            displayName: "Dow Jones Industrial Average Index",
          },
          {
            name: "INDEX:NKY",
            displayName: "Nikkei 225",
          },
          {
            name: "INDEX:DEU40",
            displayName: "DAX Index",
          },
          {
            name: "FOREXCOM:UKXGBP",
            displayName: "FTSE 100 Index",
          },
        ],
      },
      {
        name: "Futures",
        originalName: "Futures",
        symbols: [
          {
            name: "CME_MINI:ES1!",
            displayName: "S&P 500",
          },
          {
            name: "CME:6E1!",
            displayName: "Euro",
          },
          {
            name: "COMEX:GC1!",
            displayName: "Gold",
          },
          {
            name: "NYMEX:CL1!",
            displayName: "WTI Crude Oil",
          },
          {
            name: "NYMEX:NG1!",
            displayName: "Gas",
          },
          {
            name: "CBOT:ZC1!",
            displayName: "Corn",
          },
        ],
      },
      {
        name: "Bonds",
        originalName: "Bonds",
        symbols: [
          {
            name: "CBOT:ZB1!",
            displayName: "T-Bond",
          },
          {
            name: "CBOT:UB1!",
            displayName: "Ultra T-Bond",
          },
          {
            name: "EUREX:FGBL1!",
            displayName: "Euro Bund",
          },
          {
            name: "EUREX:FBTP1!",
            displayName: "Euro BTP",
          },
          {
            name: "EUREX:FGBM1!",
            displayName: "Euro BOBL",
          },
        ],
      },
      {
        name: "Forex",
        originalName: "Forex",
        symbols: [
          {
            name: "FX:EURUSD",
            displayName: "EUR to USD",
          },
          {
            name: "FX:GBPUSD",
            displayName: "GBP to USD",
          },
          {
            name: "FX:USDJPY",
            displayName: "USD to JPY",
          },
          {
            name: "FX:USDCHF",
            displayName: "USD to CHF",
          },
          {
            name: "FX:AUDUSD",
            displayName: "AUD to USD",
          },
          {
            name: "FX:USDCAD",
            displayName: "USD to CAD",
          },
        ],
      },
    ],
    showSymbolLogo: true,
    isTransparent: false,
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
    backgroundColor: darkMode ? "#131722" : "#ffffff",
  });

export const stockHeatMapConfig = (darkMode: boolean) =>
  JSON.stringify({
    exchanges: [],
    dataSource: "SPX500",
    grouping: "sector",
    blockSize: "market_cap_basic",
    blockColor: "change",
    locale: "en",
    symbolUrl: "",
    colorTheme: darkMode ? "dark" : "light",
    hasTopBar: false,
    isDataSetEnabled: false,
    isZoomEnabled: true,
    hasSymbolTooltip: true,
    isMonoSize: false,
    width: "100%",
    height: "100%",
  });

export const tickerTapeRegularConfig = (darkMode: boolean) =>
  JSON.stringify({
    symbols: [
      {
        proName: "FOREXCOM:SPXUSD",
        title: "S&P 500 Index",
      },
      {
        proName: "FOREXCOM:NSXUSD",
        title: "US 100 Cash CFD",
      },
      {
        proName: "FX_IDC:EURUSD",
        title: "EUR to USD",
      },
      {
        proName: "BITSTAMP:BTCUSD",
        title: "Bitcoin",
      },
      {
        proName: "BITSTAMP:ETHUSD",
        title: "Ethereum",
      },
    ],
    showSymbolLogo: true,
    isTransparent: false,
    displayMode: "regular",
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
  });

export const newsConfig = (darkMode: boolean) =>
  JSON.stringify({
    feedMode: "all_symbols",
    isTransparent: false,
    displayMode: "regular",
    width: "100%",
    height: "100%",
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
  });

export const singleTickerConfig = (darkMode: boolean) =>
  JSON.stringify({
    symbol: "FX:EURUSD",
    width: "100%",
    isTransparent: false,
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
  });

export const watchlistConfig = (darkMode: boolean) =>
  JSON.stringify({
    autosize: true,
    width: "100%",
    height: "100%",
    symbol: "NASDAQ:AAPL",
    interval: "D",
    timezone: "exchange",
    theme: darkMode ? "dark" : "light",
    style: "1",
    withdateranges: true,
    allow_symbol_change: true,
    save_image: false,
    watchlist: ["AAPL", "IBM", "TSLA", "AMD", "MSFT", "GOOG"],
    support_host: "https://www.tradingview.com",
  });

export const yearToDateStockHeatMapConfig = (darkMode: boolean) =>
  JSON.stringify({
    exchanges: [],
    dataSource: "SPX500",
    grouping: "no_group",
    blockSize: "market_cap_basic",
    blockColor: "Perf.YTD",
    locale: "en",
    symbolUrl: "",
    colorTheme: darkMode ? "dark" : "light",
    hasTopBar: false,
    isDataSetEnabled: false,
    isZoomEnabled: true,
    hasSymbolTooltip: true,
    width: "100%",
    height: "100%",
  });

export const tickerTapeCompactConfig = (darkMode: boolean) =>
  JSON.stringify({
    symbols: [
      {
        description: "Tesla",
        proName: "NASDAQ:TSLA",
      },
      {
        description: "Apple Inc",
        proName: "NASDAQ:AAPL",
      },
      {
        description: "Nvidia",
        proName: "NASDAQ:NVDA",
      },
      {
        description: "Microsoft",
        proName: "NASDAQ:MSFT",
      },
      {
        description: "Advanced Micro Devices",
        proName: "NASDAQ:AMD",
      },
      {
        description: "Meta",
        proName: "NASDAQ:META",
      },
      {
        description: "Netflix",
        proName: "NASDAQ:NFLX",
      },
    ],
    showSymbolLogo: true,
    colorTheme: darkMode ? "dark" : "light",
    isTransparent: false,
    displayMode: "compact",
    locale: "en",
  });

export const forexHeatMapConfig = (darkMode: boolean) =>
  JSON.stringify({
    width: "100%",
    height: "100%",
    currencies: ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD", "CNY"],
    isTransparent: false,
    colorTheme: darkMode ? "dark" : "light",
    locale: "en",
    backgroundColor: darkMode ? "#1D222D" : "#ffffff",
  });
export const tradingViewSocketToUrlMap: Map<SocketNames, string> = new Map([
  [
    "economiccalendarwidget",
    "https://s3.tradingview.com/external-embedding/embed-widget-events.js",
  ],
  [
    "stockscreener",
    "https://s3.tradingview.com/external-embedding/embed-widget-screener.js",
  ],
  [
    "marketoverview",
    "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js",
  ],
  [
    "forexcrossrates",
    "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js",
  ],
  [
    "forexheatmap",
    "https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js",
  ],
  [
    "marketdatawidget",
    "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js",
  ],
  [
    "stockheatmap",
    "https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js",
  ],
  [
    "tickertaperegular",
    "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js",
  ],
  [
    "news",
    "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js",
  ],
  [
    "singleticker",
    "https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js",
  ],
  [
    "watchlist",
    "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js",
  ],
  [
    "yeartodatestockheatmap",
    "https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js",
  ],
  [
    "tickertapecompact",
    "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js",
  ],
]);

export const getTradingComponentScriptContent = (
  socket: SocketNames,
  darkMode: boolean
) => {
  switch (socket) {
    case "stockscreener":
      return stockScreenerWidgetConfig(darkMode);

    case "forexcrossrates":
      return forexCrossRatesWidgetConfig(darkMode);

    case "marketoverview":
      return marketOverviewWidgetConfig(darkMode);

    case "marketdatawidget":
      return marketDataWidgetConfig(darkMode);

    case "economiccalendarwidget":
      return economicCalendarWidgetConfig(darkMode);

    case "forexheatmap":
      return forexHeatMapConfig(darkMode);

    case "stockheatmap":
      return stockHeatMapConfig(darkMode);

    case "tickertaperegular":
      return tickerTapeRegularConfig(darkMode);

    case "news":
      return newsConfig(darkMode);

    case "singleticker":
      return singleTickerConfig(darkMode);

    case "watchlist":
      return watchlistConfig(darkMode);

    case "yeartodatestockheatmap":
      return yearToDateStockHeatMapConfig(darkMode);

    case "tickertapecompact":
      return tickerTapeCompactConfig(darkMode);
  }
};
