import React from "react";
import { useLocation } from "react-router-dom";
import "./ProgressIndicator.scss";
import { Icon } from "@iconify/react";
import { useStepper } from "src/context/StepperProvider";
import { getUrlParam } from "src/utils/utilFunction";

export default function Stepper() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login'
  const { showStepper, steps } = useStepper();
  const withCheckout = getUrlParam("with") === "checkout";

  // Extract the 'step' parameter from the URL search params
  const searchParams = new URLSearchParams(location.search);
  const stepParam = parseInt(searchParams.get("step") || "1", 10);
  // const internalStep = parseInt(searchParams.get("internalStep") || "0", 10);
  // const emailParam = searchParams.get("email");


  // Ensure the step is within the valid range (1 to steps.length)
  const currentStep = isNaN(stepParam)
    ? 1
    : Math.min(Math.max(stepParam, 1), steps.length);

  // Function to handle the back button click
  // const handleBackClick = () => {
  //   if (currentStep === 1 && internalStep) {
  //     searchParams.delete("internalStep");
  //     navigate(`${location.pathname}?${searchParams.toString()}`);
  //     return;
  //   }
  //   if (currentStep > 1) {
  //     // Navigate to the previous step
  //     const searchParams = addUrlParam("step", (currentStep - 1)?.toString());
  //     navigate(`${location.pathname}?${searchParams}`);
  //   }
  // };

  // const isRegisterPage = useMemo(() => location.pathname === "/register", [location.pathname]);

  // console.log("step = ", currentStep, internalStep, currentStep > 1
  //   || (internalStep && internalStep !== 0))

  if (!showStepper) return null;

  return (
    <div
      style={{ opacity: !showStepper ? "0%" : "100%" }}
      className="stepper-container"
    >
      {/* {((isRegisterPage || isLoginPage) && emailParam && !!internalStep && internalStep !== 0)
        && (
          <button className="back-button" onClick={handleBackClick}>
            <Icon icon="tabler:chevron-left" className="chevron-left" />
            Back
          </button>
        )} */}
      {/* Conditionally render the back button if currentStep is greater than 1 */}
      {/* {currentStep > 1
        && (
          <button className="back-button" onClick={handleBackClick}>
            <Icon icon="tabler:chevron-left" className="chevron-left" />
            Back
          </button>
        )} */}
      <ul>
        {steps.map((step, index) => {
          const exception = isLoginPage && withCheckout && stepParam === 3
          const pastStep = exception ? index + 2 < currentStep : index + 1 < currentStep;
          const currentActiveStep = exception ? index + 2 <= currentStep : index + 1 <= currentStep

          return (
            <React.Fragment key={index}>
              <li className={currentActiveStep ? "active" : ""}>
                <span
                  className={`circle ${pastStep ? "active" : ""}`}
                  style={{
                    background: pastStep ? "#4f48e2" : "",
                    color: pastStep ? "white" : "",
                  }}
                >
                  {/* Show a check mark if the step is ahead of the current step */}
                  {pastStep && (
                    <Icon icon="fe:check" className="check-icon" />
                  )}
                </span>
                <span
                  style={{
                    position: "absolute",
                    top: 40,
                    whiteSpace: "nowrap",
                  }}
                >
                  {step}
                </span>
              </li>
              {index < steps.length - 1 && (
                <div
                  className={`line ${pastStep ? "active-line" : ""
                    }`}
                ></div>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
}
