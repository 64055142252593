import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useBookmarkContext } from 'src/context/BookmarkProvider';
import GlobalSearchLoading from '../HeaderNav/GlobalSearchLoading';
import BookmarkNotFound from './BookmarkNotFound';
import BookmarkCard from './BookmarkCard';

import closeIcon from 'src/style/images/close_icon.svg'


const Bookmark = ({ props }: any) => {
    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));
    const { bookmarks, bookmarkClickHandler, fetchMoreBookmark, setShowBookmark, hasMore } = useBookmarkContext()

    return (
        <>
            <div className="bookmark_list_container_overlay" onClick={() => setShowBookmark(false)}></div>

            <div
                className={`bookmark_list_container ${darkMode ? "dark_bookmark_list_container" : ""}`}
            >
                <div className="results">
                    <div className="closeIcon" >
                        <img src={closeIcon} alt="" onClick={() => {
                            setShowBookmark(false);
                        }} />
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={bookmarks?.length}
                    next={fetchMoreBookmark}
                    hasMore={hasMore}
                    loader={<GlobalSearchLoading />}
                    scrollableTarget={
                        "bookmark_list_container"
                    }
                    className="scrolled_data"
                    style={{ scrollbarWidth: "none", height: "100%" }}
                >
                    <div className="bookmark_wrapper">
                        {
                            bookmarks?.length === 0 ? <BookmarkNotFound /> : bookmarks?.map((data: any, i: number) => {
                                return (
                                    <BookmarkCard
                                        data={data}
                                        key={i}
                                        props={props}
                                        bookmarkClickHandler={bookmarkClickHandler}
                                        setShowBookmark={setShowBookmark}
                                    />
                                );
                            })
                        }
                    </div>
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Bookmark