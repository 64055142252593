"use client";

import React, { useEffect } from "react";

export const LocationTrackerContext = React.createContext({
    isUS: true,
    countryCode: "",
});

export const useLocationContext = () =>
    React.useContext(LocationTrackerContext);

export const LocationProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [isUS, setUS] = React.useState(true);
    const [countryCode, setCountryCode] = React.useState("");

    useEffect(() => {
        const fetchCountryName = async () => {
            try {
                const response = await fetch(
                    "https://api.bigdatacloud.net/data/reverse-geocode-client"
                );
                if (response.ok) {
                    const data = await response.json();

                    setCountryCode(data?.countryCode || "");

                    // Update isUS state based on the country code or name
                    const isUnitedStates =
                        data?.countryName === "United States of America" ||
                        data?.countryCode === "US";

                    setUS(isUnitedStates);
                    localStorage.setItem("countryCode", data?.countryCode || "");
                } else {
                    console.error("Failed to fetch geo data");
                }
            } catch (error) {
                console.error("Error fetching country name:", error);
            }
        };

        fetchCountryName();
    }, []);

    // Additional logging to check if state updates are working
    // useEffect(() => {
    //     console.log("isUS State Updated:", isUS, 'saurav');
    //     console.log("Country Code State Updated:", countryCode, 'saurav');
    // }, [isUS, countryCode]);

    return (
        <LocationTrackerContext.Provider
            value={{
                isUS,
                countryCode
            }}
        >
            {children}
        </LocationTrackerContext.Provider>
    );
};
