import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import './MyFxBookCalendar.scss';

type Props = {
    reload: boolean;
    setFetching: Dispatch<SetStateAction<boolean>>;
    setReload: Dispatch<SetStateAction<boolean>>;
}

const MyFxBookCalendarWidget = ({
    reload,
    setFetching,
    setReload
}: Props) => {

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const reloadHandler = () => {
        if (iframeRef && iframeRef?.current) {
            iframeRef.current.src += ""
            iframeRef.current.onload = () => setReload(false);
        }
        setReload(false);
        setFetching(false);
    }

    useEffect(() => {
        if (reload) {
            reloadHandler();
        }
    }, [reload])


    return (
        <>
            <iframe
                ref={iframeRef}
                className="MyFxBookCalendarWidget"
                src="https://widget.myfxbook.com/widget/calendar.html?lang=en&impacts=0,1,2,3&symbols=AUD,CAD,CHF,CNY,EUR,GBP,JPY,NZD,USD"
            ></iframe>
            <div style={{
                marginTop: "10px"
            }}>
                <div
                // style="width: fit-content; margin: auto;font-family: roboto,sans-serif!important; font-size: 13px; color: #666666;"
                >
                    <a href="https://www.myfxbook.com/forex-economic-calendar?utm_source=widget13&utm_medium=link&utm_campaign=copyright" title="Economic Calendar" className="myfxbookLink" target="_blank" rel="noopener"><b style={{ color: "#666666" }}>Economic Calendar</b></a>
                    by Myfxbook.com
                </div>
            </div >
        </>
    )
}

export default MyFxBookCalendarWidget