import React from 'react'
import Button from '../Button/Button'
import { useNavigate } from 'react-router-dom'
import { popMessage } from '../message/message'

interface BackButtonProps {
    onBack?: () => void
    disabled?: boolean
}

const BackButton: React.FC<BackButtonProps> = ({ onBack, disabled }) => {
    const navigate = useNavigate()

    const handleBack = () => {
        if (onBack) {
            onBack()
        } else {
            const googleAuth = localStorage.getItem("googleAuth");
            if (googleAuth) {
                localStorage.removeItem("googleAuth");
                localStorage.removeItem("subscriptionType");
                const newUser = localStorage.getItem("newUser");
                if (newUser === "true") {
                    popMessage.successMessage("Thanks for registering")
                    localStorage.removeItem("newUser");
                }
                navigate("/");
            } else {
                navigate(-1);
            }
        }
    }

    return (
        <Button
            className="submit-button"
            text="Back"
            type="outline"
            height="38px"
            width="102px"
            weight="bold"
            radius="sm"
            onClick={handleBack}
            buttonType="button"
            disabled={disabled}
        />
    )
}

export default BackButton