import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import './Checkout.scss';
import Toggle from "../../Toggle/Toggle";
import { CheckoutForm } from "./CheckoutForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { addUrlParam, getUrlParam } from "src/utils/utilFunction";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { popMessage } from "../../message/message";


type props = {
    nextStepHandler: () => void;
}


const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHER_KEY}`
);

const Checkout = ({ nextStepHandler }: props) => {
    const [paymentError, setPaymentError] = useState<boolean>(false);
    const [showMonthly, setShowMonthly] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();

    // const showMonthly = useMemo(() => getUrlParam("subscriptionType") === "monthly", [location?.search])
    const navigate = useNavigate();

    const { sendEvent } = useTrackerContext();

    useEffect(() => {
        const subscriptionType = searchParams.get("subscriptionType");
        if (subscriptionType) {
            setShowMonthly(subscriptionType === "monthly");
        }

    }, [location]);

    useEffect(() => {
        const handlePopState = () => {
            const newUser = localStorage.getItem("newUser");
            if (newUser === "true") {
                popMessage.successMessage("Thanks for registering")
                localStorage.removeItem("newUser");
            }
            navigate("/");
        };

        window.addEventListener("popstate", handlePopState);
        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [navigate]);


    useEffect(() => {
        return () => {
            localStorage.removeItem("googleAuth");
            localStorage.removeItem("subscriptionType");
        };
    }, [])

    const priceToggleHandler = (val: boolean) => {
        searchParams.set("subscriptionType", val ? "annually" : "monthly");
        setShowMonthly(prev => !prev);
        localStorage.setItem('currentPlanType', val ? 'annually' : 'monthly');
    }

    return <div className="auth-checkout-wrapper">
        <h1>Checkout</h1>

        <div className="pricing">
            <div>
                <span>
                    You have selected
                </span>
                <h3>Hi-IQ Plan</h3>
            </div>
            <div>
                <div className="pricing-toggle">
                    <span>
                        Monthly
                    </span>
                    <Toggle
                        height="18px"
                        width="32px"
                        checked={!showMonthly}
                        onToggle={(value: boolean) => {
                            priceToggleHandler(value)
                            sendEvent({
                                eventName: trackEvents.HIGH_IQ_CHECKOUT_PRICE_TOGGLE,
                                payload: {
                                    pricing: value ? "annually" : "monthly"
                                }
                            })
                        }}
                    />
                    <span>
                        Annually
                    </span>
                </div>
            </div>
        </div>
        {
            paymentError && <div className="checkout-error">
                <div className="image">
                    <img src="/images/alert.svg" alt="" />
                </div>
                <p>
                    Unfortunately there was an error while processing your payment. You may have entered your details incorrectly or have insufficient funds.
                </p>
            </div>
        }
        <Elements
            stripe={stripePromise}
            options={{
                mode: 'subscription',
                amount: 2000,
                currency: 'gbp',
                payment_method_types: ['card'],
                fonts: [
                    {
                        cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
                    }
                ],
                appearance: {
                    variables: {
                        fontFamily: `"Poppins",sans-serif`,
                        colorText: '#1D1D4E',
                        colorTextSecondary: '#1D1D4E',
                        colorTextPlaceholder: '#1D1D4E',
                        fontWeightLight: '300',
                    },
                    rules: {
                        '.Label': {
                            fontWeight: '500',  // Correct way to target specific elements
                            fontSize: "14px"
                        },
                        '.Input': {
                            fontWeight: '300',
                            opacity: "0.5",
                            border: "1px solid #f1f3f7",
                        }
                    },
                },
            }}
        >
            <CheckoutForm
                monthly={showMonthly}
                nextStepHandler={nextStepHandler}
                setPaymentError={setPaymentError}
                paymentError={paymentError}
            />
        </Elements>
    </div>;
};

export default Checkout;
