import { SocketNames } from "src/interface/PiqState";

export const getIframeUrl = (socket: SocketNames, darkMode: string) => {
  const theme = darkMode ? "dark" : "light";

  switch (socket) {
    case "fmoccut75bps":
      return `https://embed.polymarket.com/market.html?market=fed-decreases-interest-rates-by-75-bps-after-january-2025-meeting&features=volume&theme=${theme}`;
    case "fmoccut50bps":
      return `https://embed.polymarket.com/market.html?market=fed-decreases-interest-rates-by-50-bps-after-january-2025-meeting&features=volume&theme=${theme}`;
    case "fmoccut25bps":
      return `https://embed.polymarket.com/market.html?market=fed-decreases-interest-rates-by-25-bps-after-january-2025-meeting&features=volume&theme=${theme}`;
    case "fmocunchanged":
      return `https://embed.polymarket.com/market.html?market=no-change-in-fed-interest-rates-after-january-2025-meeting&features=volume&theme=${theme}`;
    case "fmochike25bps":
      return `https://embed.polymarket.com/market.html?market=fed-increases-interest-rates-by-25-bps-after-january-2025-meeting&features=volume&theme=${theme}`;
    default:
      return "";
  }
};

export const polymarketWidgetSockets = [
  "fmoccut75bps",
  "fmoccut50bps",
  "fmoccut25bps",
  "fmocunchanged",
  "fmochike25bps",
];
